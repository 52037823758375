<template>
  <div class="fast-timer-component">
      <div class="day">
        <span class="number">{{ days }}</span>
        <div class="format">{{ wordString.day }}</div>
      </div>
      <div class="hour">
        <span class="number">{{ hours }}</span>
        <div class="format">{{ wordString.hours }}</div>
      </div>
      <div class="min">
        <span class="number">{{ minutes }}</span>
        <div class="format">{{ wordString.minutes }}</div>
      </div>
      <div class="sec">
        <span class="number">{{ seconds }}</span>
        <div class="format">{{ wordString.seconds }}</div>
      </div>
      <div class="message">{{ message }}</div>
      <div class="status-tag" :class="statusType">{{ statusText }}</div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
// Componente baseado https://vuejsexamples.com/simple-countdown-timer-vue-component/
export default {
  name: "FastTimer",
  props: ['starttime','endtime','trans'] ,
  mixins: [methods],
  data() {
    return {
        timer:"",
        wordString: {},
        start: "",
        end: "",
        interval: "",
        days:"",
        minutes:"",
        hours:"",
        seconds:"",
        message:"",
        statusType:"",
        statusText: "",
    };
  },
  created: function () {
        this.wordString = JSON.parse(this.trans);
    },
  mounted(){
    this.getFastHoraServidor()
    .then(() => {
        this.start = this.$store.state.fastDataAtualServidor.getTime();
        this.end = new Date(this.endtime).getTime();
        // Update the count down every 1 second
        this.timerCount(this.start,this.end);

        this.interval = setInterval(() => {
            this.timerCount(this.start,this.end);
            this.start += 1000
        }, 1000);
    })
    .catch(e => this.exibeToasty(e, "error")) 

    
  },
  methods: {
    timerCount: function(start,end){
        var passTime =  end - start;

        //console.log("start", new Date(start))        
        //console.log("end", new Date(end))

        if(passTime < 0){
            this.message = this.wordString.expired;
            this.statusType = "expired";
            this.statusText = this.wordString.status.expired;
            clearInterval(this.interval);
            return;
        }else {
            this.calcTime(passTime);
            this.message = this.wordString.running;
            this.statusType = "running";
            this.statusText = this.wordString.status.running;
        } 
    },
    calcTime: function(dist){
      // Time calculations for days, hours, minutes and seconds
        this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    }
    
  }
};
</script>

<style scope>
.fast-timer-component .timer {
  font-size: 20px;
  color: #fff;
  text-align:center;
  margin-top: 50px;
}

.fast-timer-component .day, .fast-timer-component .hour, .fast-timer-component .min, .fast-timer-component .sec {
    font-size: 30px;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    margin: 0 5px;
}

.fast-timer-component .day .format, .fast-timer-component .hour .format, .fast-timer-component .min .format, .fast-timer-component .sec .format {
    font-weight: 300;
    font-size: 14px;
    opacity: 0.8;
    width: 60px;
}


.fast-timer-component .day .status-tag, .fast-timer-component .hour .status-tag, .fast-timer-component .min .status-tag, .fast-timer-component .sec .status-tag{
width: 270px;
margin: 10px auto;
padding: 8px 0;
font-weight: 500;
color: #000;
text-align: center;
border-radius: 15px;
}

.fast-timer-component .day .status-tag.upcoming, .fast-timer-component .hour .status-tag.upcoming, .fast-timer-component .min .status-tag.upcoming, .fast-timer-component .sec .status-tag.upcoming{
    background-color: lightGreen;
}
.fast-timer-component .day .status-tag.running, .fast-timer-component .hour .status-tag.running, .fast-timer-component .min .status-tag.running, .fast-timer-component .sec .status-tag.running{
    background-color: gold;
}
.fast-timer-component .day .status-tag.expired, .fast-timer-component .hour .status-tag.expired, .fast-timer-component .min .status-tag.expired, .fast-timer-component .sec .status-tag.expired{
    background-color: silver;
}

.fast-timer-component .day .message, .fast-timer-component .hour .message, .fast-timer-component .min .message, .fast-timer-component .sec .message {
font-size: 14px;
font-weight: 400;
margin-top: 5px;
}

.fast-timer-component .day .number, .fast-timer-component .hour .number, .fast-timer-component .min .number, .fast-timer-component .sec .number{
background: #f18938;
padding: 0 5px;
border-radius: 5px;
display: inline-block;
width: 60px;
text-align: center;
color: #fff;
}


</style>
