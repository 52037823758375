<template>
  <div>
    <div class="con-wrap pt-4 sala-aula-body">
      <div class="con-content">
        <!-- con-classroom -->
        <section
          class="con-classroom"
          style="max-width: 1920px"
        >
          <div
            class="con-classroom-body"
            style="column-gap: 0px"
          >
            <!-- Professor -->
            <div
              v-if="!isMobile()"
              id="professor-info"
            >
              <div
                v-if="fastAulaAtual.professor.id_professor"
                class="con-classroom-professores-list pl-0 pr-0 shadow rounded"
              >
                <div class="con-classroom-professores-list-item">
                  <div class="con-classroom-professores-list-item-header">
                    <h4>Professor</h4>
                  </div>
                  <div class="con-classroom-professores-list-item-body">
                    <div
                      id="div_professor_aula"
                      class="con-classroom-professores-list-item-professor"
                    >
                      <img :src="ajustaLinkImageUser(fastAulaAtual.professor.image)">
                      <p>
                        {{ fastAulaAtual.professor.first_name }}
                        {{ fastAulaAtual.professor.last_name }}
                      </p>
                      
                      <!-- Leila solicitou ser retirado e-mail e deixar só o nome -->

                      <!-- <div class="con-classroom-professores-list-item-professor-estado text-enter">
                        <small>{{ fastAulaAtual.professor.email }}</small>
                      </div> -->

                      <div
                        v-if="fastAulaAtual.professor.licenciatura != '0'"
                        class="con-classroom-professores-list-item-professor-titulo text-center"
                      >
                        <p>Titulação</p>
                        <div v-if="fastAulaAtual.professor.licenciatura == '1'">
                          Graduado
                        </div>
                        <div v-else-if="fastAulaAtual.professor.licenciatura == '2'">
                          Especialista
                        </div>
                        <div v-else-if="fastAulaAtual.professor.licenciatura == '3'">
                          Mestre
                        </div>
                        <div v-else-if="fastAulaAtual.professor.licenciatura == '4'">
                          Doutor
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="con-classroom-anotacoes mt-4">
                <div class="con-classroom-anotacoes-header pb-0">
                  Anotações
                  <span
                    v-if="!fastAnotacaoAtual.ativa"
                    style="cursor: pointer"
                    class="float-right text-info"
                    @click="fastAnotacaoAtual.ativa = true"
                  >
                    <b-icon-plus />
                  </span>
                </div>
                <div class="con-classroom-anotacoes-body pt-4">
                  <div class="text-right mr-2 position-relative">
                    <span
                      v-if="fastAnotacaoAtual.ativa"
                      class="btn btn-sm btn-primary"
                      @click="insereAnotacaoAtividade()"
                    >
                      <small>salvar</small>
                    </span>
                  </div>

                  <ul style="max-height: 150px; overflow: auto">
                    <li
                      v-if="fastAnotacaoAtual.ativa"
                      class="text-center text-muted"
                      style="max-height: 150px; overflow: auto"
                    >
                      <textarea
                        v-model="fastAnotacaoAtual.anotacao"
                        class="form-control"
                        cols="30"
                        rows="2"
                      />
                    </li>
                    <li v-if="!fastAnotacoes.length">
                      Clique no
                      <b-icon-plus /> e faça sua anotação aqui
                    </li>
                    <li
                      v-for="anotacao in fastAnotacoes"
                      :key="anotacao.id_usuario_atividade_anotacao"
                      class="text-left text-muted d-flex justify-content-between"
                    >
                      <a
                        href
                        @click.prevent="editarAnotacao(anotacao)"
                      >{{ anotacao.anotacao }}</a>
                      <span
                        title="Excluir anotação"
                        style="cursor: pointer"
                        class="text-danger"
                        @click="excluiAnotacoesAtividade(anotacao)"
                      >
                        <i
                          class="fa fa-trash text-danger"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                v-if="fastAtividadeAtual.tipo_atividade != 'AV' && fastAtividadeAtual.tipo_atividade != 'PS'"
                class="col-12 mt-2 pl-0 pr-0 mt-4"
              >
                <button
                  v-if="fastAtividadeAtual.tipo_atividade == 'VA'"
                  class="btn btn-classroom btn-block"
                  @click="showModal('modalCadernos')"
                >
                  <small>Adicionar video ao Caderno</small>
                </button>
                <button
                  v-else-if="fastAtividadeAtual.tipo_atividade == 'RF'"
                  class="btn btn-classroom btn-block"
                  @click="showModal('modalCadernos')"
                >
                  <small>Adicionar arquivo ao Caderno</small>
                </button>
                <button
                  v-else-if="fastAtividadeAtual.tipo_atividade == 'AD'"
                  class="btn btn-classroom btn-block"
                  @click="showModal('modalCadernos')"
                >
                  <small>Adicionar audio ao Caderno</small>
                </button>
                <button
                  v-else-if="fastAtividadeAtual.tipo_atividade == 'QZ'"
                  class="btn btn-classroom btn-block"
                  @click="showModal('modalCadernos')"
                >
                  <small>Adicionar QUIZ ao Caderno</small>
                </button>
              </div>
            </div>
            <div v-else />
            <!-- Professor -->
            <!-- Sala -->
            <div
              id="sala"
              class="show-option pl-4 pr-4"
            >         
              <div v-if="isMobile() && fastCursoAulas.length" class="text-center aluno_font_color">
                <h6 >Aula {{ fastAulaAtual.sequencia }} de {{fastCursoAulas.length}}</h6>
              </div>     
              <!-- con-timeline -->
              <section class="con-timeline">
                <div class="con-timeline-classroom">
                  <ul v-if="fastAtividades.length">
                    <li
                      v-for="atv in fastAtividades"
                      :key="atv.id_atividade"
                      :class="atv.classe"
                    >
                      <div
                        style="cursor: pointer"
                        class="con-timeline-classroom-icon d-flex justify-content-center align-items-center"
                        @click="acessaAtividadeLink(atv)"
                      >
                        <b-icon-file-earmark-text
                          v-if="atv.tipo_atividade == 'RF'"
                          font-scale="2"
                          class="p-1"
                        />
                        <b-icon-collection-play-fill
                          v-else-if="atv.tipo_atividade == 'VA'"
                          font-scale="2"
                          class="p-1"
                        />
                        <b-icon-volume-up
                          v-else-if="atv.tipo_atividade == 'AD'"
                          font-scale="2"
                          class="p-1"
                        />
                        <b-icon-camera-video
                          v-else-if="atv.tipo_atividade == 'LV'"
                          font-scale="2"
                          class="p-1"
                        />
                        <b-icon-clipboard-data
                          v-else-if="atv.tipo_atividade == 'PS'"
                          font-scale="2"
                          class="p-1"
                        />
                        <b-icon-ui-checks
                          v-else-if="atv.tipo_atividade == 'QZ'"
                          font-scale="2"
                          class="p-1"
                        />
                        <b-icon-stopwatch
                          v-else-if="atv.tipo_atividade == 'AV'"
                          font-scale="2"
                          class="p-1"
                        />
                        <b-icon-cloud-upload
                          v-else-if="atv.tipo_atividade == 'UP'"
                          font-scale="2"
                          class="p-1"
                        />
                        <b-icon-file-earmark-text
                          v-else
                          font-scale="2"
                          class="p-1"
                        />
                      </div>
                      <p class="pt-0">
                        {{ formataTipoAtividade(atv.tipo_atividade) }}
                      </p>
                    </li>
                  </ul>
                  <ul v-else>
                    <li
                      v-for="index in 3"
                      :key="index"
                      class="con-timeline-classroom"
                    >
                      <div class="con-timeline-classroom-icon d-flex justify-content-center align-items-center">
                        <b-icon-file-earmark-text
                          font-scale="2"
                          class="p-1"
                        />
                      </div>
                      <p class="pt-0">
                        Carregando...
                      </p>
                    </li>
                  </ul>
                </div>
              </section>
              
              <!-- /con-timeline -->
              <div
                v-if="fastAtividadeAtual.id_usuario_atividade"
                class="con-classroom-aula bg-white"
              >                
                <transition-group
                  name="slide-fade"
                  tag="p"
                >                  
                  <div
                    v-if="fastAtividadeAtual.tipo_atividade == 'RF'"
                    :key="0"
                  >
                    <div class="w-100 shadow rounded p-4 fast-aula-conteudo-principal">                      
                      <div
                        v-if="fastAtividadeAtual.referencia_atividade.split('.').length"
                        class="text-center"
                      >
                        <iframe
                          v-if="fastAtividadeAtual.referencia_atividade.split('.').pop() == 'ppt' || fastAtividadeAtual.referencia_atividade.split('.').pop() == 'pptx'"
                          ref="atividadePPT"
                          :src="retornaPathAtividade(fastAtividadeAtual.referencia_atividade.split('.').pop(), corrigeLinkConcurseiro(fastAtividadeAtual.referencia_atividade))"
                          frameborder="0"
                          allowfullscreen
                          class="w-100"
                          :style="{ minHeight: screenHeight / 2 + 'px' }"
                        />
                        <object
                          v-else-if="fastAtividadeAtual.referencia_atividade.split('.').pop() == 'pdf'"
                          :data="corrigeLinkConcurseiro(fastAtividadeAtual.referencia_atividade)"
                          type="application/pdf"
                          class="w-100"
                          :style="{ minHeight: screenHeight / 2 + 'px' }"
                        >
                          <embed
                            :src="corrigeLinkConcurseiro(fastAtividadeAtual.referencia_atividade)"
                            type="application/pdf"
                          >
                        </object>
                        <iframe
                          v-else-if="fastAtividadeAtual.referencia_atividade.split('.').pop() == 'html'"
                          :src="corrigeLinkConcurseiro(fastAtividadeAtual.referencia_atividade)"
                          frameborder="0"
                          allowfullscreen
                          class="w-100"
                          :style="{ minHeight: screenHeight / 2 + 'px' }"
                        />
                        <div>
                          <h6>
                            Caso o arquivo não abra corretamente, faça download do arquivo:
                            <a
                              :href="corrigeLinkConcurseiro(fastAtividadeAtual.referencia_atividade)"
                              download
                            >Download</a>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div
                    v-if="fastAtividadeAtual.tipo_atividade == 'VA'"
                    :key="1"
                  >
                    <div class="w-100 shadow rounded p-4 fast-aula-conteudo-principal bg-white">
                      <div class="text-center embed-container">
                        <iframe
                          v-if="fastAtividadeAtual.referencia_atividade.indexOf('vimeo.com') != '-1' && fastAtividadeAtual.referencia_atividade.split('/')[3] != 'video'"
                          ref="atividadeVimeo"
                          class="w-100"
                          :style="{ minHeight: screenHeight / 2 + 'px' }"
                          :src="retornaPathAtividade('vimeo', fastAtividadeAtual.referencia_atividade)"
                          frameborder="0"
                          allowfullscreen
                        />
                        <iframe
                          v-else
                          class="w-100"
                          :src="fastAtividadeAtual.referencia_atividade"
                          frameborder="0"
                          allowfullscreen
                        />
                      </div>
                    </div>
                  </div>
               
                  <div
                    v-if="fastAtividadeAtual.tipo_atividade == 'AD'"
                    :key="2"
                  >
                    <div
                      class="w-100 shadow rounded p-4 fast-aula-conteudo-principal"
                      :style="{ minHeight: screenHeight / 2 + 'px' }"
                    >
                      <div>
                        <h4 class="font-weight-bold">
                          {{ fastAtividadeAtual.titulo_atividade }}
                        </h4>
                      </div>
                      <div v-if="fastAtividadeAtual.detalhe_atividade">
                        <span>{{ fastAtividadeAtual.detalhe_atividade }}</span>
                      </div>
                      <div class="text-center mt-4">
                        <audio controls>
                          <source
                            :src="ajustaLinkApiAntiga(fastAtividadeAtual.referencia_atividade)"
                            type="audio/mpeg"
                          >
                          Seu navegador não suporta mp3
                        </audio>
                      </div>
                    </div>
                  </div>
              
                  <div
                    v-if="fastAtividadeAtual.tipo_atividade == 'QZ'"
                    :key="3"
                  >
                    <div
                      class="w-100 shadow rounded p-4 fast-aula-conteudo-principal"
                      :style="{ minHeight: screenHeight / 2 + 'px' }"
                    >
                      <div>
                        <h4 class="font-weight-bold">
                          {{ fastAtividadeAtual.titulo_atividade }}
                        </h4>
                      </div>
                      <div v-if="fastAtividadeAtual.detalhe_atividade">
                        <span>{{ fastAtividadeAtual.detalhe_atividade }}</span>
                      </div>
                      <div v-else>
                        <span>Você pode responder o QUIZ quantas vezes você desejar.</span>
                      </div>

                      <div class="text-center mt-4 mb-4">
                        <button
                          v-if="fastAtividadeAtual.quiz"
                          class="btn btn-primary"
                          @click="showModal('modalQuiz')"
                        >
                          <span v-if="fastAtividadeAtual.quiz_finalizado"><b-icon-question-circle /> Revisar QUIZ</span>
                          <span v-else-if="fastAtividadeAtual.questoes_pendentes"><b-icon-question-circle /> Continuar QUIZ</span>
                          <span v-else><b-icon-question-circle /> Iniciar QUIZ</span>
                        </button>
                      </div>
                      <div class="d-flex justify-content-center">
                        <VueApexCharts
                          width="380"
                          :options="chartOptions"
                          :series="chartDataQuiz"
                        />
                      </div>                    
                    </div>
                  </div>
               
                  <div
                    v-if="fastAtividadeAtual.tipo_atividade == 'PS'"
                    :key="4"
                  >
                    <div
                      class="w-100 shadow rounded p-4 fast-aula-conteudo-principal"
                      :style="{ minHeight: screenHeight / 2 + 'px' }"
                    >
                      <div>
                        <h4 class="font-weight-bold">
                          {{ fastAtividadeAtual.titulo_atividade }}
                        </h4>
                      </div>
                      <div v-if="fastAtividadeAtual.detalhe_atividade">
                        <span>{{ fastAtividadeAtual.detalhe_atividade }}</span>
                      </div>

                      <div class="text-center mt-2 mb-4">
                        <button
                          v-if="fastAtividadeAtual.pesquisa"
                          class="btn btn-primary"
                          @click="showModal('modalPesquisa')"
                        >
                          <span v-if="fastAtividadeAtual.pesquisa_finalizada">Rever Pesquisa</span>
                          <span v-else-if="fastAtividadeAtual.pesquisas_pendentes">Continuar Pesquisa</span>
                          <span v-else>Iniciar Pesquisa</span>
                        </button>
                      </div>
                    </div>
                  </div>
              
                  <div
                    v-if="fastAtividadeAtual.tipo_atividade == 'AV'"
                    :key="5"
                  >
                    <div
                      class="w-100 shadow rounded p-4 fast-aula-conteudo-principal"
                      :style="{ minHeight: screenHeight / 2 + 'px' }"
                    >
                      <div>
                        <h4 class="font-weight-bold">
                          {{ fastAtividadeAtual.titulo_atividade }}
                        </h4>
                      </div>
                      <div v-if="fastAtividadeAtual.detalhe_atividade">
                        <span>{{ fastAtividadeAtual.detalhe_atividade }}</span>
                      </div>
                      <div class="mt-2">
                        <h6 v-if="fastAtividadeAtual.tempo_atividade">
                          <strong>Tempo limite:</strong>
                          {{ formataTempoLimite() }}
                        </h6>
                        <h6 v-if="fastCursoPlataforma.nota_maxima">
                          <strong>Nota máxima do curso:</strong>
                          {{fastCursoPlataforma.nota_maxima}}
                        </h6>
                        <h6 v-if="fastCursoPlataforma.nota_media">
                          <strong>Nota média do curso:</strong>
                          {{fastCursoPlataforma.nota_media}}
                        </h6>
                        <h6>
                          <strong>Peso da avaliação:</strong>
                          {{ fastAtividadeAtual.prova_peso ? fastAtividadeAtual.prova_peso : "Sem peso" }}
                        </h6>                       
                        <h6>
                          <strong>Tentativas máximas:</strong>
                          {{ fastAtividadeAtual.tentativas_maxima ? fastAtividadeAtual.tentativas_maxima : "ilimitada" }}
                        </h6>
                        <h6>
                          <strong>Tentativas realizadas:</strong>
                          {{ fastAtividadeAtual.count ? fastAtividadeAtual.count : "0" }}
                        </h6>
                         <h6 v-if="fastAtividadeAtual.prova_peso">
                          <strong>Sua nota:</strong>
                          {{fastNotaAvaliacao}}
                        </h6>
                        <div
                          v-if="fastAtividadeAtual.data_previsto_fim && !fastAtividadeAtual.data_fim && fastAtividadeAtual.tempo_atividade && fastAtividadeAtual.tempo_atividade != '00:00:00' && !fastLoadingAvalicao"
                          class="mt-4 d-flex"
                        >
                          <fast-timer 
                            :endtime="fastAtividadeAtual.data_previsto_fim" 
                            trans='{  
                            "day":"Dias",
                            "hours":"Horas",
                            "minutes":"Minutos",
                            "seconds":"Segundos",
                            "expired":"A live irá terminar em:",
                            "running":"",
                            "upcoming":"",
                            "status": {
                                "expired":"",
                                "running":"",
                                "upcoming":""
                              }}'
                            ></fast-timer>
                        </div>
                      </div>

                      <div
                        v-if="fastLoadingAvalicao"
                        class="text-center mt-4 mb-4 d-flex justify-content-center"
                      >
                        <h4>Aguarde um momento...</h4>
                      </div>
                      <div
                        v-else
                        class="text-center mt-4 mb-4 d-flex justify-content-center"
                      >
                        <div v-if="!fastAtividadeAtual.data_fim">
                          <button
                            v-if="!fastAtividadeAtual.avaliacoes_pendentes && !fastAtividadeAtual.avaliacao_finalizada"
                            class="btn btn-primary ml-1 mr-1"
                            @click="iniciarAvaliacao('iniciar')"
                          >
                            <span>Iniciar Avaliação</span>
                          </button>
                          <button
                            v-else-if="fastAtividadeAtual.avaliacoes_pendentes"
                            class="btn btn-primary ml-1 mr-1 mt-2"
                            @click="iniciarAvaliacao('continuar')"
                          >
                            <span>Continuar Avaliação</span>
                          </button>
                          <button
                            v-else-if="fastAtividadeAtual.avaliacao_finalizada"
                            class="btn btn-primary ml-1 mr-1 mt-2"
                            @click="showModal('modalAvaliacao')"
                          >
                            <span>Revisar Avaliação</span>
                          </button>
                          <button
                            v-if="fastAtividadeAtual.avaliacao_finalizada"
                            class="btn btn-danger ml-1 mr-1 mt-2"
                            @click="finalizaAtividadeAtual()"
                          >
                            Finalizar avaliação
                          </button>
                        </div>
                        <div v-else>                          
                          <div
                            v-if="fastAtividadeAtual.count < fastAtividadeAtual.tentativas_maxima || !fastAtividadeAtual.tentativas_maxima || fastAtividadeAtual.tentativas_maxima == '0'"
                            class="mb-4"
                          >
                            <div v-if="fastAtividadeAtual.refazendo_avaliacao">
                              <button
                                class="btn btn-primary ml-1 mr-1"
                                @click="showModal('modalAvaliacao')"
                              >
                                <span>Revisar Avaliação</span>
                              </button>
                              <button
                                class="btn btn-danger ml-1 mr-1"
                                @click="finalizaAvaliacaoTentativa()"
                              >
                                <span>Finalizar avaliação</span>
                              </button>
                            </div>
                            <button
                              v-else
                              class="btn btn-primary ml-1 mr-1"
                              @click="iniciarAvaliacao('refazer')"
                            >
                              <span>Fazer novamente</span>
                            </button>
                          </div>
                          <div v-if="chartAvaliacaoVisivel" class="col-12 text-center mb-4">
                              <button 
                                @click="provaDownloadGabarito()"
                                class="btn btn-success ml-1 mr-1"
                              >
                                <small>Download do gabarito</small>
                              </button>
                            </div>
                          <div
                            v-if="chartAvaliacaoVisivel"
                            class="d-lg-flex justify-content-center"
                          >
                            <div class="d-flex justify-content-center">
                              <VueApexCharts
                                width="380"
                                :options="chartOptionsProvaPeso"
                                :series="chartDataProvaPeso"
                              />
                            </div>
                            <div class="d-flex justify-content-center">
                              <VueApexCharts
                                width="380"
                                :options="chartOptions"
                                :series="chartDataProva"
                              />
                            </div>
                          </div>
                          <!-- Upload de gabarito -->
                          <div class="row mt-4">
                            
                            <div v-if="!fastPathGabarito" class="col-12 mt-2">
                              <uploader
                                :file-status-text="statusText"
                                :options="optionsArquivoGabarito"
                                class="uploader-example"
                                @file-success="fileSuccessGabarito"
                                @file-added="fileValidation"
                              >
                                <uploader-unsupport />
                                <uploader-drop>
                                  <p>Enviar gabarito assinado (somente pdf, jpg, jpeg, gif ou png)</p>
                                  <uploader-btn :attrs="restrictArquivo">
                                    Clique aqui para selecionar
                                  </uploader-btn>
                                </uploader-drop>
                                <uploader-list />
                              </uploader>
                            </div>
                            <div v-if="fastPathGabarito" class="col-12 mt-2">
                              <a :href="ajustaLinkApiAntiga(fastPathGabarito)" target="_blank">
                                <h4>Gabarito assinado</h4>                                
                              </a> 
                              <button class="btn btn-sm btn-primary" @click="fastPathGabarito = ''">
                                  <b-icon-arrow-counterclockwise font-scale="1" /> Reenviar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
                  <div
                    v-if="fastAtividadeAtual.tipo_atividade == 'LV'"
                    :key="6"
                  >
                    <div
                      class="w-100 shadow rounded p-4 fast-aula-conteudo-principal"
                      :style="{ minHeight: screenHeight / 2 + 'px' }"
                    >
                      <div>
                        <h4 class="font-weight-bold">
                          {{ fastAtividadeAtual.titulo_atividade }}
                        </h4>
                      </div>
                      <div v-if="fastAtividadeAtual.detalhe_atividade">
                        <span>{{ fastAtividadeAtual.detalhe_atividade }}</span>
                      </div>
                      <div class="text-center mt-4">
                        <div
                          v-if="fastLive.id_live"
                          class="row"
                        >
                          <div
                            v-if="fastLive.status == 'A'"
                            class="col-12"
                          >
                            <div>
                              <h4>
                                Live agendada para o dia
                                {{ fastLive.data_live.split("T")[0].split("-")[2] + "/" + fastLive.data_live.split("T")[0].split("-")[1] + "/" + fastLive.data_live.split("T")[0].split("-")[0] }}
                                às {{ fastLive.hora_live }}
                              </h4>
                            </div>
                            <fast-timer 
                              :endtime="fastLive.data_live.split('T')[0] + ' ' + fastLive.hora_live"
                              trans='{  
                              "day":"Dias",
                              "hours":"Horas",
                              "minutes":"Minutos",
                              "seconds":"Segundos",
                              "expired":"A live irá iniciar em instantes",
                              "running":"",
                              "upcoming":"",
                              "status": {
                                  "expired":"",
                                  "running":"",
                                  "upcoming":""
                                }}'
                              ></fast-timer>
                            <div>
                              <a
                                :href="'/live/' + fastLive.id_live"
                                class="btn btn-primary mt-2"
                              >Acessar Live</a>
                            </div>
                          </div>
                          <div
                            v-else-if="fastLive.status == 'E'"
                            class="col-12"
                          >
                            <h4>A live iniciou!</h4>
                            <div>
                              <a
                                :href="'/live/' + fastLive.id_live"
                                class="btn btn-primary mt-2"
                              >Acessar Live</a>
                            </div>
                          </div>
                          <div
                            v-else-if="fastLive.status == 'F'"
                            class="col-12"
                          >
                            <h4>A live já foi finalizada!</h4>
                          </div>
                          <div
                            v-else-if="fastLive.status == 'C'"
                            class="col-12"
                          >
                            <h4>A live foi cancelada!</h4>
                          </div>
                          <div
                            v-else
                            class="col-12"
                          >
                            <h4>Live não agendada</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
                  <div
                    v-if="fastAtividadeAtual.tipo_atividade == 'UP'"
                    :key="7"
                  >
                    <div
                      class="w-100 shadow rounded p-4 fast-aula-conteudo-principal"
                      :style="{ minHeight: screenHeight / 2 + 'px' }"
                    >
                      <div>
                        <h4 class="font-weight-bold">
                          {{ fastAtividadeAtual.titulo_atividade }}
                        </h4>
                      </div>
                      <div v-if="fastAtividadeAtual.detalhe_atividade">
                        <span>{{ fastAtividadeAtual.detalhe_atividade }}</span>
                      </div>

                      <div class="mt-4">
                        <div
                          v-if="fastAtividadeAtual.uploads.length"
                          class="row"
                        >
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <strong>Envios</strong>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              class="btn btn-sm btn-primary"
                              @click="showModal('modalUpload')"
                            >
                              Reenviar atividade
                            </button>
                          </div>
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm table-striped">
                              <thead class="thead-dark">
                                <tr>
                                  <th />
                                  <th>
                                    <small class="font-weight-bold">Data do envio</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Corrigido</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(u, index) in fastAtividadeAtual.uploads"
                                  :key="u.id_usuario_upload_anexo"
                                >
                                  <td>
                                    <small>{{ index + 1 }}</small>
                                  </td>
                                  <td>
                                    <small>{{ new Date(u.data_hora).toLocaleString().split(" ")[0] }} {{ u.data_hora.split("T")[1] }}</small>
                                  </td>
                                  <td class="text-center">
                                    <i
                                      v-if="u.corrigido == 'S'"
                                      class="far fa-check-circle fa-2x text-success"
                                    />
                                    <span
                                      v-else
                                      class="text-warning"
                                    >Pendente</span>
                                  </td>
                                  <td class="text-center">
                                    <button
                                      class="btn btn-sm btn-success"
                                      @click="exibeModalUploadEnvios(u)"
                                    >
                                      Visualizar
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          v-else
                          class="row text-center"
                        >
                          <div class="col-12">
                            <h6>Nenhum envio</h6>
                            <button
                              class="btn btn-sm btn-primary"
                              @click="showModal('modalUpload')"
                            >
                              Enviar atividade
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </div>
              <div
                v-else
                class="con-classroom-aula bg-white"
              >
                <div
                  class="w-100 shadow rounded p-4 fast-aula-conteudo-principal d-flex justify-content-center align-items-center"
                  :style="{ minHeight: screenHeight / 2 + 'px' }"
                >
                  <div>
                    <h3 style="color: #d98840">
                      Carregando atividades
                    </h3>
                    <div class="spinner">
                      <div class="bounce1" />
                      <div class="bounce2" />
                      <div class="bounce3" />
                    </div>
                  </div>
                </div>
              </div>

              <transition name="slide-fade">
                <div
                  v-if="!fastLoadingAvalicao"
                  id="div_conteudo_atividades_btn"
                  class="con-classroom-aula-options mt-0"
                >
                  <div class="d-flex">
                    <button
                      v-if="fastAtividadeAtual.atividade_antes"
                      class="btn con-classroom-options-prev mt-0"
                      style="font-size: 20px"
                      @click="voltaAtividade()"
                    >
                      <img :src="require('@/assets/images/btn-arrow-prev.png')">Anterior
                    </button>
                    <button
                      v-else-if="!fastAtividadeAtual.atividade_antes && fastAulaAnterior.id_aula"
                      class="btn con-classroom-options-prev mt-0"
                      style="font-size: 20px"
                      @click="acessarAula(fastAulaAnterior)"
                    >
                      <img :src="require('@/assets/images/btn-arrow-prev.png')">
                      Aula anterior
                    </button>
                    <button
                      v-if="fastAtividadeAtual.atividade_depois"
                      class="btn con-classroom-options-next ml-auto mt-0"
                      style="font-size: 20px"
                      @click="proximaAtividade()"
                    >
                      Próximo
                      <img :src="require('@/assets/images/btn-arrow-next.png')">
                    </button>
                    <button
                      v-else-if="!fastAtividadeAtual.atividade_depois && fastAulaPosterior.id_aula"
                      class="btn con-classroom-options-next ml-auto mt-0"
                      style="font-size: 20px"
                      @click="acessarAula(fastAulaPosterior)"
                    >
                      Próxima aula
                      <img :src="require('@/assets/images/btn-arrow-next.png')">
                    </button>
                  </div>
                </div>
              </transition>
              <transition name="slide-fade">
                <div
                  v-if="!fastLoadingAvalicao"
                  class="con-classroom-aula-options mt-0"
                >
                  <div class="text-center">
                    <a
                      class="btn con-classroom-options-exit text-white"
                      style="font-size: 20px"
                      @click.prevent="sairAula()"
                    >
                      Sair
                      <img :src="require('@/assets/images/btn-logout-icon.png')">
                    </a>
                  </div>
                </div>
              </transition>
            </div>
            <!-- Sala -->
            <!-- Opções -->
            <div
              v-if="!isMobile()"
              id="opcoes-recursos-sala"
            >
              <div class="con-classroom-anotacoes-desativado shadow rounded">
                <div class="con-classroom-anotacoes-header pb-0">
                  <span>Aula {{ fastAulaAtual.sequencia }} - {{ fastAulaAtual.titulo_aula }}</span>
                </div>
                <div class="con-classroom-anotacoes-body p-4">
                  <p id="sala_detalhe_aula">
                    {{ fastAulaAtual.detalhe_aula }}
                  </p>
                </div>
              </div>
              <div
                v-if="fastUsuarioRanking.nome_nivel"
                class="con-classroom-anotacoes-desativado shadow rounded mt-4"
              >
                <div class="con-classroom-anotacoes-header pb-0">
                  <div class="d-flex justify-content-between">
                    <span>
                      <img
                        v-if="fastUsuarioRanking.imagem_nivel"
                        style="width: auto; max-height: 30px"
                        :src="ajustaLinkApiAntiga(fastUsuarioRanking.imagem_nivel)"
                      >
                      {{ fastUsuarioRanking.nome_nivel }}
                    </span>
                    <div
                      v-if="fastPlataformaMedalhas.length"
                      class="d-flex mr-2"
                    >
                      <div
                        v-for="medalha in fastPlataformaMedalhas"
                        :key="medalha.nivel_medalha"
                      >
                        <img
                          style="height: 40px"
                          :src="ajustaLinkApiAntiga(medalha.imagem_medalha)"
                        >
                        <span v-if="medalha.quantidade">{{ medalha.quantidade }}</span>
                        <span v-else>0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="con-classroom-anotacoes-body p-4 d-flex flex-wrap">
                  <div
                    :style="{
                      backgroundImage: 'url(' + ajustaLinkImageUser(imageUsuario) + ')',
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      backgroundSize: '100%',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '50%',
                    }"
                  />
                  <div class="d-flex justify-content-center align-items-center ml-4">
                    <div>
                      <span class="font-weight-bold">{{ fastUsuarioRanking.pontos_usuario }} pontos</span>
                    </div>
                    <div class="ml-4">
                      <span
                        class="btn btn-sm btn-primary"
                        style="cursor: pointer"
                        @click="showModal('modalRegrasRanking')"
                      >Ver Regras</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="con-classroom-anotacoes-desativado shadow rounded mt-4">
                <div class="con-classroom-anotacoes-header pb-0">
                  <span>Lista de aulas</span>
                </div>
                <div
                  class="con-classroom-anotacoes-body p-4 lista-aulas-scroll-rtl"
                  style="max-height: 50vh; overflow-y: auto"
                >
                  <div
                    v-if="fastCursoAulasLoading"
                    class="text-center"
                  >
                    <h6 style="color: #d98840">
                      Carregando lista
                    </h6>
                    <div class="spinner">
                      <div class="bounce1" />
                      <div class="bounce2" />
                      <div class="bounce3" />
                    </div>
                  </div>
                  <vue-tree-list
                    :model="treeAulas"
                    default-tree-node-name="Aula"
                    :default-expanded="false"
                    @delete-node="acessarAula"
                  >
                    <template v-slot:leafNameDisplay="slotProps">
                      <small :class="slotProps.model.classe + ' fast-iuea-color'">{{ slotProps.model.name }}</small>
                    </template>
                    <span
                      slot="delNodeIcon"
                      title="Escolher essa disciplina"
                      class="icon"
                    >
                      <button class="btn btn-sm btn-success pt-0 pb-0">
                        <small>Acessar</small>
                      </button>
                    </span>
                    <span
                      slot="addTreeNodeIcon"
                      title="Acessar aula"
                      class="icon d-none"
                    />

                    <span
                      slot="addLeafNodeIcon"
                      class="icon d-none"
                    />
                    <span
                      slot="editNodeIcon"
                      title="Editar"
                      class="icon d-none"
                    />
                    <span
                      slot="treeNodeIcon"
                      class="icon"
                    />
                  </vue-tree-list>
                </div>
              </div>
            </div>
            <div v-else />
            <!-- Opções -->
          </div>
        </section>
        <div v-if="isMobile()">
          <div class="text-center">
            <h6 class="font-weight-bold">
              Recursos da aula
            </h6>
          </div>
          <div class="d-flex flex-wrap justify-content-center mb-2">
            <div v-if="fastAtividadeAtual.tipo_atividade != 'AV' && fastAtividadeAtual.tipo_atividade != 'PS'">
              <button
                v-if="fastAtividadeAtual.tipo_atividade == 'VA'"
                class="btn btn-sm btn-secondary ml-1 mr-1 mb-2"
                @click="showModal('modalCadernos')"
              >
                <small>+ Video ao Caderno</small>
              </button>
              <button
                v-else-if="fastAtividadeAtual.tipo_atividade == 'RF'"
                class="btn btn-sm btn-secondary ml-1 mr-1 mb-2"
                @click="showModal('modalCadernos')"
              >
                <small>+ Arquivo ao Caderno</small>
              </button>
              <button
                v-else-if="fastAtividadeAtual.tipo_atividade == 'AD'"
                class="btn btn-sm btn-secondary ml-1 mr-1 mb-2"
                @click="showModal('modalCadernos')"
              >
                <small>+ audio ao Caderno</small>
              </button>
              <button
                v-else-if="fastAtividadeAtual.tipo_atividade == 'QZ'"
                class="btn btn-sm btn-secondary ml-1 mr-1 mb-2"
                @click="showModal('modalCadernos')"
              >
                <small>+ QUIZ ao Caderno</small>
              </button>
            </div>
            <button
              class="btn btn-sm btn-secondary ml-1 mr-1 mb-2"
              @click="showModal('modalMobileProfessor')"
            >
              <small> <b-icon-eye-fill /> Professor </small>
            </button>
            <button
              class="btn btn-sm btn-secondary ml-1 mr-1 mb-2"
              @click="showModal('modalMobileAnotacoes')"
            >
              <small> <b-icon-pencil-square /> Anotações </small>
            </button>

            <button
              class="btn btn-sm btn-secondary ml-1 mr-1 mb-2"
              @click="showModal('modalMobileRanking')"
            >
              <small> <b-icon-eye-fill /> Meu ranking </small>
            </button>
            <button
              class="btn btn-sm btn-secondary ml-1 mr-1 mb-2 d-none"
              @click="showModal('modalMobileAulas')"
            >
              <small> <b-icon-eye-fill /> Aulas </small>
            </button>
          </div>
        </div>

        <!-- /con-classroom -->
      </div>
    </div>
    <!-- modal -->
    <modal
      name="modalQuiz"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Quiz</h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalQuiz')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 text-info">
              <small>Para alternar entre as perguntas basta arrastar!</small>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row p-lg-4">
            <div class="col-12 pl-lg-4 pr-lg-4">
              <div class="row pl-2 pr-2 pb-lg-4">
                <carousel v-if="isMobile()"
                  :navigation-enabled="true"
                  :per-page="1"
                  :touch-drag="true"
                  :mouse-drag="true"
                  :adjustable-height="true"
                  :pagination-size="20"
                  :paginationEnabled="false"
                  :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded"
                >
                  <slide
                    v-for="(q, index) in fastAtividadeAtual.quiz"
                    :key="q.id_questao"
                  >
                    <div class="col-12 mt-2">
                      <h5>
                        Questão {{ index + 1 }} de
                        {{ fastAtividadeAtual.quiz.length }}
                      </h5>
                    </div>

                    <div class="col-12 mt-2 max-width-100vw">
                      <div v-html="q.pergunta" />
                    </div>
                    <div
                      v-if="q.alternativas.length"
                      class="col-12 p-2 max-width-100vw"
                    >
                      <div
                        v-for="(a, index2) in q.alternativas"
                        :key="a.id_alternativa"
                        class="row pl-2 pr-2"
                      >
                        <div class="col-12">
                          <div
                            v-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'S'"
                            :class="'d-flex fast-aula-alternativa-escolhida-correta'"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'N'"
                            :class="'d-flex fast-aula-alternativa-escolhida-incorreta'"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else
                            :class="'d-flex fast-aula-alternativa'"
                            @click="respondeQuestaoQuiz(q, a)"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>

                <carousel v-else
                  :navigation-enabled="true"
                  :per-page="1"
                  :touch-drag="true"
                  :mouse-drag="true"
                  :adjustable-height="true"
                  :pagination-size="20"
                  :paginationEnabled="true"
                  :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded"
                >
                  <slide
                    v-for="(q, index) in fastAtividadeAtual.quiz"
                    :key="q.id_questao"
                  >
                    <div class="col-12 mt-2">
                      <h5>
                        Questão {{ index + 1 }} de
                        {{ fastAtividadeAtual.quiz.length }}
                      </h5>
                    </div>

                    <div class="col-12 mt-2 max-width-100vw">
                      <div v-html="q.pergunta" />
                    </div>
                    <div
                      v-if="q.alternativas.length"
                      class="col-12 p-2 max-width-100vw"
                    >
                      <div
                        v-for="(a, index2) in q.alternativas"
                        :key="a.id_alternativa"
                        class="row pl-2 pr-2"
                      >
                        <div class="col-12">
                          <div
                            v-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'S'"
                            :class="'d-flex fast-aula-alternativa-escolhida-correta'"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else-if="a.opcao_escolhida == a.id_alternativa && q.acertou == 'N'"
                            :class="'d-flex fast-aula-alternativa-escolhida-incorreta'"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else
                            :class="'d-flex fast-aula-alternativa'"
                            @click="respondeQuestaoQuiz(q, a)"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalPesquisa"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Pesquisa</h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalPesquisa')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row p-lg-4">
            <div class="col-12 pl-lg-4 pr-lg-4">
              <div class="row pl-2 pr-2 pb-lg-4">
                <carousel
                  :navigation-enabled="true"
                  :per-page="1"
                  :touch-drag="true"
                  :mouse-drag="true"
                  :adjustable-height="true"
                  :pagination-size="20"
                  :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded"
                >
                  <slide
                    v-for="(pergunta, index) in fastAtividadeAtual.pesquisa"
                    :key="pergunta.id_pergunta"
                  >
                    <div class="col-12 mt-2 max-width-100vw">
                      <h5>
                        Pesquisa {{ index + 1 }} de
                        {{ fastAtividadeAtual.pesquisa.length }}
                      </h5>
                    </div>

                    <div class="col-12 mt-2">
                      <div v-html="pergunta.html" />
                    </div>
                    <div
                      v-if="pergunta.alternativas.length"
                      class="col-12 p-2 max-width-100vw"
                    >
                      <div v-if="pergunta.tipo_pesquisa == 'O'">
                        <div
                          v-for="(a, index2) in pergunta.alternativas"
                          :key="a.id_resposta"
                          class="row pl-2 pr-2"
                        >
                          <div class="col-12">
                            <div
                              v-if="a.opcao_escolhida == a.id_resposta || a.opcao_escolhida == 'S'"
                              :class="'d-flex fast-aula-alternativa-escolhida'"
                            >
                              <div class="pr-2">
                                <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                              </div>
                              <div>
                                <div v-html="a.html_resposta" />
                              </div>
                            </div>
                            <div
                              v-else
                              :class="'d-flex fast-aula-alternativa'"
                              @click="respondePesquisa(pergunta, a)"
                            >
                              <div class="pr-2">
                                <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                              </div>
                              <div>
                                <div v-html="a.html_resposta" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-center"
                      >
                        <star-rating
                          v-model="pergunta.alternativas[0].estrelas_escolhida"
                          :show-rating="false"
                          :max-rating="pergunta.alternativas[0].estrelas"
                          @rating-selected="respondePesquisa(pergunta, pergunta.alternativas[0])"
                        />
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAvaliacao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Avaliação</h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalAvaliacao')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 text-info">
              <small>Para alternar entre as perguntas basta arrastar!</small>
            </div>
          </div>
        </div>
        <div
          v-if="fastAtividadeAtual.data_previsto_fim && fastAtividadeAtual.tempo_atividade && fastAtividadeAtual.tempo_atividade != '00:00:00'"
          class="col-12 d-flex justify-content-center"
        >
          <fast-timer 
                    :endtime="fastAtividadeAtual.data_previsto_fim" 
                    trans='{  
                    "day":"Dias",
                    "hours":"Horas",
                    "minutes":"Minutos",
                    "seconds":"Segundos",
                    "running":"",
                    "upcoming":"",
                    "status": {
                        "expired":"",
                        "running":"",
                        "upcoming":""
                      }}'
                    ></fast-timer>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row p-lg-4">
            <div class="col-12 pl-lg-4 pr-lg-4">
              <div class="row pl-2 pr-2 pb-lg-4">
                <carousel v-if="isMobile()"
                  :navigation-enabled="true"
                  :per-page="1"
                  :touch-drag="true"
                  :mouse-drag="true"
                  :adjustable-height="true"
                  :pagination-size="20"
                  :paginationEnabled="false"
                  :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded"
                >
                  <slide
                    v-for="(q, index) in fastAtividadeAtual.avaliacao"
                    :key="q.id_questao"
                  >
                    <div class="col-12 mt-2 max-width-100vw">
                      <h5>
                        Questão {{ index + 1 }} de
                        {{ fastAtividadeAtual.avaliacao.length }}
                      </h5>
                    </div>

                    <div class="col-12 mt-2">
                      <div v-html="q.pergunta" />
                    </div>
                    <div
                      v-if="q.alternativas.length"
                      class="col-12 p-2 max-width-100vw"
                    >
                      <div
                        v-for="(a, index2) in q.alternativas"
                        :key="a.id_alternativa"
                        class="row pl-2 pr-2"
                      >
                        <div class="col-12">
                          <div
                            v-if="a.opcao_escolhida == a.id_alternativa"
                            :class="'d-flex fast-aula-alternativa-escolhida'"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else
                            :class="'d-flex fast-aula-alternativa'"
                            @click="respondeQuestaoAvaliacao(q, a)"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>
                
                <carousel v-else
                  :navigation-enabled="true"
                  :per-page="1"
                  :touch-drag="true"
                  :mouse-drag="true"
                  :adjustable-height="true"
                  :pagination-size="20"
                  :paginationEnabled="true"
                  :min-swipe-distance="100"
                  class="col-12 p-2 mb-2 bg-white rounded"
                >
                  <slide
                    v-for="(q, index) in fastAtividadeAtual.avaliacao"
                    :key="q.id_questao"
                  >
                    <div class="col-12 mt-2 max-width-100vw">
                      <h5>
                        Questão {{ index + 1 }} de
                        {{ fastAtividadeAtual.avaliacao.length }}
                      </h5>
                    </div>

                    <div class="col-12 mt-2">
                      <div v-html="q.pergunta" />
                    </div>
                    <div
                      v-if="q.alternativas.length"
                      class="col-12 p-2 max-width-100vw"
                    >
                      <div
                        v-for="(a, index2) in q.alternativas"
                        :key="a.id_alternativa"
                        class="row pl-2 pr-2"
                      >
                        <div class="col-12">
                          <div
                            v-if="a.opcao_escolhida == a.id_alternativa"
                            :class="'d-flex fast-aula-alternativa-escolhida'"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                          <div
                            v-else
                            :class="'d-flex fast-aula-alternativa'"
                            @click="respondeQuestaoAvaliacao(q, a)"
                          >
                            <div class="pr-2">
                              <h6>{{ retornaLetraAlternativa(index2) }})</h6>
                            </div>
                            <div>
                              <div v-html="a.alternativa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalRegrasRanking"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Regras</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalRegrasRanking')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 pl-4 pr-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-1">
              <h6>Parâmetros do ranking</h6>
            </div>
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>
                      <small class="font-weight-bold">Atividade</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Pontos</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar atividade geral
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_atividade_geral }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Questão correta no QUIZ
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_acerto_questao_quiz }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold text-danger">
                      Questão errada no QUIZ
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_erro_questao_quiz }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar curso
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_completar_curso }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar curso acima da média
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_completar_curso_acima_media }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar matriz
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_completar_matriz }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar avaliação
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_completar_prova }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar avaliação acima da média
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_completar_prova_acima_media }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Participação em live
                    </td>

                    <td class="text-center align-middle">
                      {{ fastRankingParametros.ponto_participar_live }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-1">
                  <h6>Lista de níveis</h6>
                </div>
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">
                          <small class="font-weight-bold">Nível</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Pontos necessários</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastRanking.length">
                      <tr
                        v-for="r in fastRanking"
                        :key="r.nivel"
                      >
                        <td class="align-middle">
                          <small>
                            <img
                              :src="ajustaLinkApiAntiga(r.imagem_nivel)"
                              width="70px"
                            >
                            {{ r.nome_nivel }}
                          </small>
                        </td>
                        <td class="text-center align-middle">
                          <small>{{ r.ponto_inicial }}</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-1">
                  <h6>Lista de medalhas</h6>
                </div>
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">
                          <small class="font-weight-bold">Nível</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Porcentagem de acerto em avaliação</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastPlataformaMedalhas.length">
                      <tr
                        v-for="medalha in fastPlataformaMedalhas"
                        :key="medalha.nivel_medalha"
                      >
                        <td class="align-middle">
                          <small>
                            <img
                              :src="ajustaLinkApiAntiga(medalha.imagem_medalha)"
                              width="70px"
                            >
                            {{ medalha.nome_medalha }}
                          </small>
                        </td>
                        <td class="text-center align-middle">
                          <small>{{ medalha.porcentagem_acerto_prova }}%</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCadernos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h4>Meus cadernos</h4>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-2 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalCadernos')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row p-lg-4">
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-8 mb-2">
                  <input
                    v-model="fastCadernoNovo.nome_caderno"
                    type="text"
                    class="form-control"
                    placeholder="Nome do caderno"
                  >
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                  <button
                    class="btn btn-primary btn-block"
                    @click="cadastraNovoCaderno()"
                  >
                    <small>Criar caderno</small>
                  </button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                  <table class="table table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" />
                        <th scope="col">
                          Nome
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                        >
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="pageOfCadernos.length">
                      <tr
                        v-for="(caderno, index) in pageOfCadernos"
                        :key="caderno.id_caderno"
                      >
                        <td scope="row">
                          {{ index + 1 }}
                        </td>
                        <td class="align-middle">
                          {{ caderno.nome_caderno }}
                        </td>
                        <td class="align-middle text-center">
                          <button
                            class="btn btn-sm btn-success"
                            title="Adicionar ao caderno"
                            @click="adicionaAtividadeCaderno(caderno)"
                          >
                            <small> <b-icon-person-plus-fill /> Adicionar </small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          class="text-center"
                          colspan="4"
                        >
                          Nenhum caderno cadastrado
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 text-center mt-4">
                  <Pagination
                    :page-size="5"
                    :items="cadernosFiltro"
                    @changePage="pageOfCadernos = $event"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalUpload"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
      :shift-y="0.1"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Envio de atividade</h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalUpload')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row pr-lg-4 pl-lg-4 pb-4">
            <div class="col-12">
              <h6>Insira aqui a sua resposta</h6>
            </div>
            <div class="col-12">
              <quill-editor
                v-model="fastUsuarioUploadNovo.html_texto"
                :options="editorOption"
              />
            </div>
            <div class="col-12 mt-4">
              <h6>Enviar anexos:</h6>
            </div>
            <div class="col-12">
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccess"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p>Arraste um arquivo aqui ou</p>
                  <uploader-btn :attrs="restrictArquivo">
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
            <div class="col-12 mt-4 text-center">
              <button
                class="btn btn-primary"
                @click="insereFastUsuarioUpload()"
              >
                Confirmar envio
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalUploadEnvios"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Envios</h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalUploadEnvios')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row pr-lg-4 pl-lg-4 pb-4">
            <div class="col-12 mt-4">
              <div
                v-if="fastUsuarioUploadVisualizar.id_usuario_upload"
                class="row"
              >
                <div
                  class="col-12"
                  v-html="fastUsuarioUploadVisualizar.html_texto"
                />
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th />
                        <th>
                          <small class="font-weight-bold">Anexo</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="u in fastUsuarioUploadVisualizar.anexos"
                        :key="u.id_usuario_upload_anexo"
                      >
                        <td>
                          <small>{{ u.sequencia }}</small>
                        </td>
                        <td>
                          <small>
                            <a
                              v-if="u.path"
                              :href="ajustaLinkApiAntiga(u.path)"
                              target="_blank"
                              download
                            >Anexo_{{ u.sequencia }}.{{ u.path.split(".").pop() }}</a>
                            <span v-else>Nenhum anexo</span>
                          </small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                v-else
                class="row"
              >
                <div class="col-12">
                  <h6>Esta atividade não possui nenhum envio</h6>
                </div>
              </div>
            </div>
            <div
              v-if="fastUsuarioUploadProfessor.id_usuario_upload"
              class="col-12"
            >
              <div class="row">
                <div class="col-12">
                  <h4>Corrigido por {{ fastUsuarioUploadProfessor.nome_usuario }}</h4>
                </div>
                <div
                  class="col-12 pb-4"
                  v-html="fastUsuarioUploadProfessor.html_texto"
                />
                <div class="col-12 table-responsive mt-2 pb-4">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th />
                        <th>
                          <small class="font-weight-bold">Anexo</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="u in fastUsuarioUploadProfessor.anexos"
                        :key="u.id_usuario_upload_anexo"
                      >
                        <td>
                          <small>{{ u.sequencia }}</small>
                        </td>
                        <td>
                          <small>
                            <a
                              v-if="u.path"
                              :href="ajustaLinkApiAntiga(u.path)"
                              target="_blank"
                              download
                            >Anexo_{{ u.sequencia }}.{{ u.path.split(".").pop() }}</a>
                            <span v-else>Nenhum anexo</span>
                          </small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- Modal para mobile -->
    <modal
      name="modalMobileProfessor"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Professor</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-sm btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMobileProfessor')"
          >
            <small>fechar</small>
          </a>
        </div>

        <div class="col-12 pl-4 pr-4">
          <div
            v-if="fastAulaAtual.professor.id_professor"
            class="con-classroom-professores-list pl-0 pr-0 shadow rounded"
          >
            <div>
              <div class="p-4">
                <div
                  id="div_professor_aula"
                  class="con-classroom-professores-list-item-professor"
                >
                  <img :src="ajustaLinkImageUser(fastAulaAtual.professor.image)">
                  <p>
                    {{ fastAulaAtual.professor.first_name }}
                    {{ fastAulaAtual.professor.last_name }}
                  </p>
                  <!-- Leila solicitou ser retirado e-mail e deixar só o nome -->

                  <!-- <div class="con-classroom-professores-list-item-professor-estado text-enter">
                    <small>{{ fastAulaAtual.professor.email }}</small>
                  </div> -->

                  <div
                    v-if="fastAulaAtual.professor.licenciatura != '0'"
                    class="con-classroom-professores-list-item-professor-titulo text-center"
                  >
                    <p>Titulação</p>
                    <div v-if="fastAulaAtual.professor.licenciatura == '1'">
                      Graduado
                    </div>
                    <div v-else-if="fastAulaAtual.professor.licenciatura == '2'">
                      Especialista
                    </div>
                    <div v-else-if="fastAulaAtual.professor.licenciatura == '3'">
                      Mestre
                    </div>
                    <div v-else-if="fastAulaAtual.professor.licenciatura == '4'">
                      Doutor
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-center"
          >
            <h6>Nenhum professor</h6>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalMobileAnotacoes"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Anotações</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-sm btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMobileAnotacoes')"
          >
            <small>fechar</small>
          </a>
        </div>

        <div class="col-12 pl-4 pr-4">
          <div class="con-classroom-anotacoes mt-4">
            <div class="con-classroom-anotacoes-header pb-0">
              Anotações
              <span
                v-if="!fastAnotacaoAtual.ativa"
                style="cursor: pointer"
                class="float-right text-info"
                @click="fastAnotacaoAtual.ativa = true"
              >
                <b-icon-plus />
              </span>
            </div>
            <div class="con-classroom-anotacoes-body pt-4">
              <div class="text-right mr-2 position-relative">
                <span
                  v-if="fastAnotacaoAtual.ativa"
                  class="btn btn-sm btn-primary"
                  @click="insereAnotacaoAtividade()"
                >
                  <small>salvar</small>
                </span>
              </div>

              <ul style="max-height: 150px; overflow: auto">
                <li
                  v-if="fastAnotacaoAtual.ativa"
                  class="text-center text-muted"
                  style="max-height: 150px; overflow: auto"
                >
                  <textarea
                    v-model="fastAnotacaoAtual.anotacao"
                    class="form-control"
                    cols="30"
                    rows="2"
                  />
                </li>
                <li v-if="!fastAnotacoes.length">
                  Clique no
                  <b-icon-plus /> e faça sua anotação aqui
                </li>
                <li
                  v-for="anotacao in fastAnotacoes"
                  :key="anotacao.id_usuario_atividade_anotacao"
                  class="text-left text-muted d-flex justify-content-between"
                >
                  <a
                    href
                    @click.prevent="editarAnotacao(anotacao)"
                  >{{ anotacao.anotacao }}</a>
                  <span
                    title="Excluir anotação"
                    style="cursor: pointer"
                    class="text-danger"
                    @click="excluiAnotacoesAtividade(anotacao)"
                  >
                    <i
                      class="fa fa-trash text-danger"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalMobileRanking"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Meu ranking</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-sm btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMobileRanking')"
          >
            <small>fechar</small>
          </a>
        </div>

        <div class="col-12 pl-4 pr-4">
          <div
            v-if="fastUsuarioRanking.nome_nivel"
            class="con-classroom-anotacoes-desativado shadow rounded mt-4"
          >
            <div class="con-classroom-anotacoes-header pb-0">
              <div class="d-flex justify-content-between">
                <span>
                  <img
                    v-if="fastUsuarioRanking.imagem_nivel"
                    style="width: auto; max-height: 30px"
                    :src="ajustaLinkApiAntiga(fastUsuarioRanking.imagem_nivel)"
                  >
                  {{ fastUsuarioRanking.nome_nivel }}
                </span>
                <div
                  v-if="fastPlataformaMedalhas.length"
                  class="d-flex mr-2"
                >
                  <div
                    v-for="medalha in fastPlataformaMedalhas"
                    :key="medalha.nivel_medalha"
                  >
                    <img
                      style="height: 40px"
                      :src="ajustaLinkApiAntiga(medalha.imagem_medalha)"
                    >
                    <span v-if="medalha.quantidade">{{ medalha.quantidade }}</span>
                    <span v-else>0</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="con-classroom-anotacoes-body p-4 d-flex flex-wrap">
              <div
                :style="{
                  backgroundImage: 'url(' + ajustaLinkImageUser(imageUsuario) + ')',
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50%',
                }"
              />
              <div class="d-flex justify-content-center align-items-center">
                <div>
                  <span class="font-weight-bold">{{ fastUsuarioRanking.pontos_usuario }} pontos</span>
                </div>
                <div class="ml-4">
                  <span
                    class="btn btn-sm btn-primary"
                    style="cursor: pointer"
                    @click="showModal('modalRegrasRanking', 'modalMobileRanking')"
                  >Ver Regras</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalMobileAulas"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Lista de aulas</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-sm btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMobileAulas')"
          >
            <small>fechar</small>
          </a>
        </div>

        <div class="col-12">
          <div class="con-classroom-anotacoes-desativado shadow rounded mt-4">
            <div class="con-classroom-anotacoes-header pb-0">
              <span>Lista de aulas</span>
            </div>
            <div
              class="con-classroom-anotacoes-body p-4"
              style="max-height: 350px; overflow-y: auto"
            >
              <vue-tree-list
                :model="treeAulas"
                default-tree-node-name="Aula"
                :default-expanded="false"
                @delete-node="acessarAula"
              >
                <template v-slot:leafNameDisplay="slotProps">
                  <small :class="slotProps.model.classe">{{ slotProps.model.name }}</small>
                </template>
                <span
                  slot="delNodeIcon"
                  title="Escolher essa disciplina"
                  class="icon"
                >
                  <button class="btn btn-sm btn-success pt-0 pb-0">
                    <small>Acessar</small>
                  </button>
                </span>
                <span
                  slot="addTreeNodeIcon"
                  title="Acessar aula"
                  class="icon d-none"
                />

                <span
                  slot="addLeafNodeIcon"
                  class="icon d-none"
                />
                <span
                  slot="editNodeIcon"
                  title="Editar"
                  class="icon d-none"
                />
                <span
                  slot="treeNodeIcon"
                  class="icon"
                />
              </vue-tree-list>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <VueHtml2pdf
      ref="html2Pdf"
      class="mx-auto"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="960px"
      :manual-pagination="false"
      :paginate-elements-by-height="10000"
    >
      <section slot="pdf-content">
        <div v-html="htmlProvaGabarito" />
      </section>
    </VueHtml2pdf>

    <img id="logo_plataforma" :src="logo_plataforma" class="d-none">

    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
  
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme
import VueApexCharts from "vue-apexcharts"; //https://github.com/apexcharts/vue-apexcharts https://apexcharts.com/vue-chart-demos
import StarRating from "vue-star-rating";
import Pagination from "../components/Pagination";
import { VueTreeList, Tree, TreeNode } from "vue-tree-list"; //https://github.com/ParadeTo/vue-tree-list#readme
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import FastTimer from "../components/FastTimer"

import { quillEditor } from "vue-quill-editor";

import VueHtml2pdf from "vue-html2pdf"; //https://www.npmjs.com/package/vue-html2pdf

//const imageToBase64 = require('image-to-base64'); //https://www.npmjs.com/package/image-to-base64

export default {
  name: "HomeInternoAlunoCursoAula",
  components: {
    Carousel,
    Slide,
    VueApexCharts,
    StarRating,
    FastTimer,
    Pagination,
    VueTreeList,
    quillEditor,
    VueHtml2pdf
  },
  mixins: [methods],
  data: function() {
    return {
      fastTemplate: settings.fastTemplate,
      nomeUsuario: "",
      imageUsuario: this.retornaSessao(settings.fastSessaoUsuario).Image,
      nome_plataforma: this.retornaSessao(settings.fastSessaoPlataforma).nome_plataforma,
      banner_plataforma: "",
      logo_plataforma: this.retornaSessao(settings.fastSessaoPlataforma).logo_plataforma,
      // Tela
      screenHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      // Largura do modal
      modalWidth: "80%",
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Atividade
      fastAtividades: [],
      fastAtividadeAtual: {
        data_fim: "",
        data_inicio: "",
        data_previsto_fim: "",
        data_previsto_inicio: "",
        detalhe_atividade: "",
        id_apostila: "",
        id_aula: "",
        id_curso: 0,
        id_usuario: "",
        id_usuario_atividade: "",
        id_video: "",
        incrementa_evolucao: "",
        publicada: "",
        referencia_atividade: "",
        sequencia: "",
        tempo_atividade: "",
        tempo_executado: "",
        tipo_atividade: "",
        titulo_atividade: "",
        tentativas_maxima: "",
        count: "",
        refazendo_avaliacao: "",
        quiz: [],
        pesquisa: [],
        avaliacao: [],
        uploads: [],
        pesquisa_finalizada: false,
        pesquisas_pendentes: false,
        avaliacao_finalizada: false,
        avaliacoes_pendentes: false,
        refazendo_avaliacao: false,
      },
      fastNotaAvaliacao: "",
      fastPesoAvaliacao: "",
      // Chart
      chartOptions: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Acertos", "Erros"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptionsProvaPeso: {
        colors: ["#1fcc1b", "#f42727"],
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Nota atingida", "Nota restante"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartDataQuiz: [0, 0],
      chartDataProva: [0, 0],
      chartDataProvaPeso: [0, 0],
      chartAvaliacaoVisivel: true,
      fastAvaliacaoTempoIniciada: false,
      countDownSettings: {
        days: "Dias",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
      },
      // Aula
      fastAulaAtual: {
        detalhe_aula: "",
        evoulucao_aula: "",
        id_aula: "",
        id_curso: 0,
        id_professor: "",
        publicada: "",
        sequencia: "",
        titulo_aula: "",
        professor: {
          email: "",
          first_name: "",
          id_plataforma: 0,
          id_professor: "",
          id_professor_concurseiro: "",
          image: "",
          last_name: "",
          licenciatura: "",
        },
      },
      fastCursoAulas: [],
      fastCursoAulasLoading: true,
      // Ranking
      fastRanking: [],
      fastRankingParametros: {
        id_plataforma: 0,
        id_ranking_parametro: 0,
        ponto_acerto_questao_quiz: 0,
        ponto_atividade_geral: 0,
        ponto_completar_curso: 0,
        ponto_completar_curso_acima_media: 0,
        ponto_completar_matriz: 0,
        ponto_completar_prova: 0,
        ponto_completar_prova_acima_media: 0,
        ponto_erro_questao_quiz: 0,
        ponto_participar_live: 0,
      },
      fastUsuarioRanking: {
        id_plataforma: 0,
        imagem_nivel: "",
        nivel: 0,
        nome_nivel: "",
        ponto_final: 0,
        ponto_inicial: 0,
        pontos_usuario: 0,
        status: "",
      },
      fastPlataformaMedalhas: [],
      // Anotações
      fastAnotacaoAtual: {
        id_usuario_atividade_anotacao: 0,
        id_usuario_atividade: "",
        anotacao: "",
        data_hora: null,
        ativa: false,
      },
      fastAnotacoes: [],
      // Cadernos
      cadernosTotal: [],
      cadernosFiltro: [],
      pageOfCadernos: [],
      fastCadernoNovo: {
        id_caderno: 0,
        nome_caderno: "",
        id_usuario: 0,
        datacriacao: null,
        data_alteracao: null,
      },
      // Lista de aulas
      // Disciplinas
      treeAulas: new Tree({
        name: "Primeira aula",
        id: 0,
        isLeaf: false,
        addLeafNodeDisabled: true,
      }),
      // Live
      fastLive: {
        id_live: "",
        API_TOKEN: "",
        nr_maximo_sala: "",
        data_live: "",
        hora_live: "",
        id_usuario_tutor: "",
        id_usuario_moderador: "",
        detalhes: "",
        titulo_live: "",
        id_plataforma: 0,
        status: "",
        video_registro: "",
        duracao_live: "",
        acesso: "R",
        id_usuario_responsavel: "",
        tipo: "Z",
        participantes: [],
      },
      // Upload
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },
      fastUsuarioUploadNovo: {
        id_usuario_atividade: "",
        html_texto: "",
        paths: [],
      },
      // Upload arquivo
      optionsArquivo: {
        target: settings.endApiFastEad + "api/fast_curso_aula_atividade/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: false,
        query: {
          tipo_atividade: "UP",
          id_aula: this.$route.params.id_aula,
          id_atividade: "",
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "odt", "doc", "docx", "ppt", "pptx", "gif", "jpg", "jpeg", "png", "rar"],
        },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept: "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/x-rar-compressed,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      fastUsuarioUploadVisualizar: {
        id_usuario_upload: "",
        data_hora: "",
        html_texto: "",
        anexos: [],
      },
      fastUsuarioUploadProfessor: {
        id_usuario_upload: "",
        data_hora: "",
        html_texto: "",
        anexos: [],
      },
      fastAulaPosterior: {},
      fastAulaAnterior: {},
      // Loading
      fastLoadingAvalicao: false,
      // FastCurso
      fastCursoPlataforma: {
        acesso: "",
        carga_horaria: "",
        data_disponivel: "",
        data_expiracao: "",
        detalhe_curso: "",
        id_curso: 0,
        id_plataforma: 0,
        id_registro_curso_plataforma: "",
        id_requisicao: "",
        id_usuario_responsavel: "",
        libera_automatica: "",
        logo_curso: "",
        multidisciplinar: "",
        nome_curso: "",
        origem: "",
        publicado: "",
        quantidade: "",
        nota_maxima: "",
        nota_media: "",
        nome_curso_mae: ""
      },
      // Gabarito
      fastPathGabarito: "",
      htmlProvaGabarito: "",
      htmlToPdfOptions: {
        margin: 0.5,
        filename: 'Gabarito.pdf',
        image: {
            type: 'jpeg',
            quality: 1
        },
        enableLinks: false,
        html2canvas: {
            scale: 1,
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            width: 960,
        },
        jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait'
        }
      },
      optionsArquivoGabarito: {
        target: settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/upload_gabarito",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: false,
        query: {
          id_usuario_atividade: 0,
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "odt", "doc", "docx", "ppt", "pptx", "gif", "jpg", "jpeg", "png", "rar"],
        },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
    };
  },
  created: function() {
    this.isMobile() ? (this.modalWidth = "100%") : (this.modalWidth = "80%");
  },
  mounted: function() {
    if (this.$route.params.id_plataforma && this.$route.params.id_curso && this.$route.params.id_aula) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.exibeAulasCurso();

          if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
            this.exibeAulaSelecionadaSecretaria();
          } else {
            this.exibeAulaSelecionada();
          }          

          this.getFastAulaDetalhes();
          this.getFastPlataformaMedalhas();
          this.getFastRanking();
          this.getFastRankingParametros();
          this.getCadernosUsuario();
          // Curso
          this.getFastCursoPlataforma()
        })
        .catch((e) => {
          this.exibeToasty(e, "error");

          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Curso
    async getFastCursoPlataforma() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma/lista_curso_aluno?id_usuario=&id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCursoPlataforma = obj[0];
        } 

      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Atividades
    async exibeAulaSelecionada() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/lista_aula_selecionada?id_curso=" + this.$route.params.id_curso + "&id_aula=" + this.$route.params.id_aula + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          // A atividade atual será a passada pela url
          if (this.getUrlParameter("atividade")) {
            // Percorre atividades
            obj.some((a, index) => {
              // A primeira atividade não possuir data_fim será a atual
              if (this.getUrlParameter("atividade") == a.id_atividade) {
                // Seta os atributos das atividades
                a.quiz = [];
                a.pesquisa = [];
                a.avaliacao = [];
                a.uploads = [];
                a.pesquisa_finalizada = false;
                a.pesquisas_pendentes = false;
                a.avaliacao_finalizada = false;
                a.avaliacoes_pendentes = false;
                a.refazendo_avaliacao = false;
                a.prova_peso = 0;
                // Ajusta data fim
                if (a.data_previsto_fim) {
                  a.data_previsto_fim = a.data_previsto_fim.split("T")[0] + " " + a.data_previsto_fim.split("T")[1];

                  // Hack para timer
                  let startTimeEpoch = new Date(a.data_previsto_fim).getTime() / 1000
                  let d = new Date(0);
                  d.setUTCSeconds(startTimeEpoch);
                  let month = d.getMonth() + 1;
                  let day = d.getDate();
                  if (month < 10) month = "0" + month;
                  if (day < 10) day = "0" + day;
                  a.data_previsto_fim =
                  d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + a.data_previsto_fim.split(':')[1] + ":" + a.data_previsto_fim.split(':')[2];

                }
                // Define como atividade atual
                this.fastAtividadeAtual = a;
                this.optionsArquivoGabarito.query.id_usuario_atividade = a.id_usuario_atividade
                // Verifica atividade próxima e anterior se existem
                if (obj[index - 1]) this.fastAtividadeAtual.atividade_antes = true;
                if (obj[index + 1]) this.fastAtividadeAtual.atividade_depois = true;
                return true;
              }
            });
          } else {
            // Percorre atividades
            obj.some((a, index) => {
              // A primeira atividade não possuir data_fim será a atual
              if (!a.data_fim) {
                // Seta os atributos das atividades
                a.quiz = [];
                a.pesquisa = [];
                a.avaliacao = [];
                a.uploads = [];
                a.pesquisa_finalizada = false;
                a.pesquisas_pendentes = false;
                a.avaliacao_finalizada = false;
                a.avaliacoes_pendentes = false;
                a.refazendo_avaliacao = false;
                a.prova_peso = 0;
                // Ajusta data fim
                if (a.data_previsto_fim) {
                  a.data_previsto_fim = a.data_previsto_fim.split("T")[0] + " " + a.data_previsto_fim.split("T")[1];

                  // Hack para timer
                  let startTimeEpoch = new Date(a.data_previsto_fim).getTime() / 1000
                  let d = new Date(0);
                  d.setUTCSeconds(startTimeEpoch);
                  let month = d.getMonth() + 1;
                  let day = d.getDate();
                  if (month < 10) month = "0" + month;
                  if (day < 10) day = "0" + day;
                  a.data_previsto_fim =
                  d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + a.data_previsto_fim.split(':')[1] + ":" + a.data_previsto_fim.split(':')[2];

                }
                // Define como atividade atual
                this.fastAtividadeAtual = a;
                this.optionsArquivoGabarito.query.id_usuario_atividade = a.id_usuario_atividade
                // Verifica atividade próxima e anterior se existem
                if (obj[index - 1]) this.fastAtividadeAtual.atividade_antes = true;
                if (obj[index + 1]) this.fastAtividadeAtual.atividade_depois = true;
                return true;
              }
            });
          }

          // Se todas atividades tiverem finalizado a atividade atual estará nula
          if (!this.fastAtividadeAtual.id_usuario_atividade) {
            // Define a atividade atual como a última
            obj[obj.length - 1].quiz = [];
            obj[obj.length - 1].pesquisa = [];
            obj[obj.length - 1].avaliacao = [];
            obj[obj.length - 1].uploads = [];
            obj[obj.length - 1].pesquisa_finalizada = false;
            obj[obj.length - 1].pesquisas_pendentes = false;
            obj[obj.length - 1].avaliacao_finalizada = false;
            obj[obj.length - 1].avaliacoes_pendentes = false;
            obj[obj.length - 1].refazendo_avaliacao = false;
            obj[obj.length - 1].prova_peso = 0;

            this.fastAtividadeAtual = obj[obj.length - 1];
            if (obj[obj.length - 2]) this.fastAtividadeAtual.atividade_antes = true;
          }

          // Configura as classes dos ícones de visualização das atividades
          obj.forEach((a) => {
            // Seta os atributos das atividades para todas
            a.quiz = [];
            a.pesquisa = [];
            a.avaliacao = [];
            a.uploads = [];
            a.pesquisa_finalizada = false;
            a.pesquisas_pendentes = false;
            a.avaliacao_finalizada = false;
            a.avaliacoes_pendentes = false;
            a.refazendo_avaliacao = false;
            a.prova_peso = 0;
            // Ajusta data fim
            /*if (a.data_previsto_fim) {              
              a.data_previsto_fim =
                a.data_previsto_fim.split("T")[0] +
                " " +
                a.data_previsto_fim.split("T")[1].split(".")[0];
            }*/
            a.classe = "con-timeline-classroom";
            if (a.id_usuario_atividade == this.fastAtividadeAtual.id_usuario_atividade) a.classe = "con-timeline-classroom-assistindo";
            if (a.data_fim) a.classe = "con-timeline-classroom-visualizado";
          });

          // Armazena todas as atividades da aula
          this.fastAtividades = obj;

          // Inicia atividade atual
          console.log("this.fastAtividadeAtual", this.fastAtividadeAtual)
          this.iniciaAtividade(this.fastAtividadeAtual);

          // Busca anotações
          this.getAnotacoesAtividade();
        } else {
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async exibeAulaSelecionadaSecretaria() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/lista_aula_selecionada_secretaria?id_curso=" + this.$route.params.id_curso + "&id_aula=" + this.$route.params.id_aula + "&id_plataforma=" + this.$route.params.id_plataforma + "&id_programa_turma=" + parseInt(this.base64decode(this.getUrlParameter('programa'))), this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          // A atividade atual será a passada pela url
          if (this.getUrlParameter("atividade")) {
            // Percorre atividades
            obj.some((a, index) => {
              // A primeira atividade não possuir data_fim será a atual
              if (this.getUrlParameter("atividade") == a.id_atividade) {
                // Seta os atributos das atividades
                a.quiz = [];
                a.pesquisa = [];
                a.avaliacao = [];
                a.uploads = [];
                a.pesquisa_finalizada = false;
                a.pesquisas_pendentes = false;
                a.avaliacao_finalizada = false;
                a.avaliacoes_pendentes = false;
                a.refazendo_avaliacao = false;
                a.prova_peso = 0;
                // Ajusta data fim
                if (a.data_previsto_fim) {
                  a.data_previsto_fim = a.data_previsto_fim.split("T")[0] + " " + a.data_previsto_fim.split("T")[1];

                  // Hack para timer
                  let startTimeEpoch = new Date(a.data_previsto_fim).getTime() / 1000
                  let d = new Date(0);
                  d.setUTCSeconds(startTimeEpoch);
                  let month = d.getMonth() + 1;
                  let day = d.getDate();
                  if (month < 10) month = "0" + month;
                  if (day < 10) day = "0" + day;
                  a.data_previsto_fim =
                  d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + a.data_previsto_fim.split(':')[1] + ":" + a.data_previsto_fim.split(':')[2];

                }
                // Define como atividade atual
                this.fastAtividadeAtual = a;
                this.optionsArquivoGabarito.query.id_usuario_atividade = a.id_usuario_atividade

                // Verifica atividade próxima e anterior se existem
                if (obj[index - 1]) this.fastAtividadeAtual.atividade_antes = true;
                if (obj[index + 1]) this.fastAtividadeAtual.atividade_depois = true;
                return true;
              }
            });
          } else {
            // Percorre atividades
            obj.some((a, index) => {
              // A primeira atividade não possuir data_fim será a atual
              if (!a.data_fim) {
                // Seta os atributos das atividades
                a.quiz = [];
                a.pesquisa = [];
                a.avaliacao = [];
                a.uploads = [];
                a.pesquisa_finalizada = false;
                a.pesquisas_pendentes = false;
                a.avaliacao_finalizada = false;
                a.avaliacoes_pendentes = false;
                a.refazendo_avaliacao = false;
                a.prova_peso = 0;
                // Ajusta data fim
                if (a.data_previsto_fim) {
                  a.data_previsto_fim = a.data_previsto_fim.split("T")[0] + " " + a.data_previsto_fim.split("T")[1];

                  // Hack para timer
                  let startTimeEpoch = new Date(a.data_previsto_fim).getTime() / 1000
                  let d = new Date(0);
                  d.setUTCSeconds(startTimeEpoch);
                  let month = d.getMonth() + 1;
                  let day = d.getDate();
                  if (month < 10) month = "0" + month;
                  if (day < 10) day = "0" + day;
                  a.data_previsto_fim =
                  d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + a.data_previsto_fim.split(':')[1] + ":" + a.data_previsto_fim.split(':')[2];

                }
                // Define como atividade atual
                this.fastAtividadeAtual = a;
                this.optionsArquivoGabarito.query.id_usuario_atividade = a.id_usuario_atividade
                // Verifica atividade próxima e anterior se existem
                if (obj[index - 1]) this.fastAtividadeAtual.atividade_antes = true;
                if (obj[index + 1]) this.fastAtividadeAtual.atividade_depois = true;
                return true;
              }
            });
          }

          // Se todas atividades tiverem finalizado a atividade atual estará nula
          if (!this.fastAtividadeAtual.id_usuario_atividade) {
            // Define a atividade atual como a última
            obj[obj.length - 1].quiz = [];
            obj[obj.length - 1].pesquisa = [];
            obj[obj.length - 1].avaliacao = [];
            obj[obj.length - 1].uploads = [];
            obj[obj.length - 1].pesquisa_finalizada = false;
            obj[obj.length - 1].pesquisas_pendentes = false;
            obj[obj.length - 1].avaliacao_finalizada = false;
            obj[obj.length - 1].avaliacoes_pendentes = false;
            obj[obj.length - 1].refazendo_avaliacao = false;
            obj[obj.length - 1].prova_peso = 0;

            this.fastAtividadeAtual = obj[obj.length - 1];
            if (obj[obj.length - 2]) this.fastAtividadeAtual.atividade_antes = true;
          }

          // Configura as classes dos ícones de visualização das atividades
          obj.forEach((a) => {
            // Seta os atributos das atividades para todas
            a.quiz = [];
            a.pesquisa = [];
            a.avaliacao = [];
            a.uploads = [];
            a.pesquisa_finalizada = false;
            a.pesquisas_pendentes = false;
            a.avaliacao_finalizada = false;
            a.avaliacoes_pendentes = false;
            a.refazendo_avaliacao = false;
            a.prova_peso = 0;
            // Ajusta data fim
            /*if (a.data_previsto_fim) {              
              a.data_previsto_fim =
                a.data_previsto_fim.split("T")[0] +
                " " +
                a.data_previsto_fim.split("T")[1].split(".")[0];
            }*/
            a.classe = "con-timeline-classroom";
            if (a.id_usuario_atividade == this.fastAtividadeAtual.id_usuario_atividade) a.classe = "con-timeline-classroom-assistindo";
            if (a.data_fim) a.classe = "con-timeline-classroom-visualizado";
          });

          // Armazena todas as atividades da aula
          this.fastAtividades = obj;

          // Inicia atividade atual
          console.log("this.fastAtividadeAtual", this.fastAtividadeAtual)
          this.iniciaAtividade(this.fastAtividadeAtual);

          // Busca anotações
          this.getAnotacoesAtividade();
        } else {
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async iniciaAtividade(atividade) {
      console.log("iniciaAtividade")
      // Se não possui data de início atualiza a data, com exceção da avaliação que inicia após o clique do usuário
      if (!atividade.data_inicio && atividade.tipo_atividade != "AV") {
        let fast_usuario_curso_aula_atividade = {
          id_usuario_atividade: atividade.id_usuario_atividade,
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza_data_inicio_novo", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_curso_aula_atividade)));
          let json = await resp.json();
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      }
      if (this.fastAtividadeAtual != atividade) {
        // Ajusta ícones (as atividades QZ e AV só finalizam após responder todas as questões)
        if ((this.fastAtividadeAtual.tipo_atividade == "QZ" && !this.fastAtividadeAtual.quiz_finalizado) || (this.fastAtividadeAtual.tipo_atividade == "AV" && !this.fastAtividadeAtual.avaliacao_finalizada && !this.fastAtividadeAtual.data_fim)) {
          this.fastAtividadeAtual.classe = "con-timeline-classroom";
        } else {
          // Altera ícone da atividade atual para já visualizado
          this.fastAtividadeAtual.classe = "con-timeline-classroom-visualizado";
        }
        // Altera ícona da atividade clicada para assistindo
        atividade.classe = "con-timeline-classroom-assistindo";

        //let referencia = this.fastAtividadeAtual.referencia_atividade;
        //this.fastAtividadeAtual.referencia_atividade = "";
        //setTimeout(() => {
        //this.fastAtividadeAtual.referencia_atividade = referencia;
        // Muda atividade atual para a clicada
        this.fastAtividadeAtual = atividade;
        this.optionsArquivoGabarito.query.id_usuario_atividade = this.fastAtividadeAtual.id_usuario_atividade
        // Verifica botões próximo e anterior
        this.verificaAnteriorProximo();
        // Busca anotações
        this.getAnotacoesAtividade();
        //}, 1000);
      }
      if (atividade.tipo_atividade == "QZ") {
        this.getQuizQuestoes(atividade);
      } else if (atividade.tipo_atividade == "PS") {
        this.getFastUsuarioPesquisa(atividade);
        // Só busca questões da avaliação se já iniciou
      } else if (atividade.tipo_atividade == "AV") {
        this.getPesoAvaliacao();
        // Continua avaliação
        if (atividade.data_inicio && !atividade.data_fim) {
          if (this.fastAtividadeAtual.data_previsto_fim)
            if (this.fastAtividadeAtual.data_previsto_fim.indexOf("T") != "-1") this.fastAtividadeAtual.data_previsto_fim = this.fastAtividadeAtual.data_previsto_fim.split("T")[0] + " " + this.fastAtividadeAtual.data_previsto_fim.split("T")[1].split(".")[0];

          // Hack para timer
          let startTimeEpoch = new Date(this.fastAtividadeAtual.data_previsto_fim).getTime() / 1000
          let d = new Date(0);
          d.setUTCSeconds(startTimeEpoch);
          let month = d.getMonth() + 1;
          let day = d.getDate();
          if (month < 10) month = "0" + month;
          if (day < 10) day = "0" + day;
          if (this.fastAtividadeAtual.data_previsto_fim)
            this.fastAtividadeAtual.data_previsto_fim = d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[1] + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[2];          

          this.iniciarAvaliacao("continuar");
          // Finalizou
        } else if (atividade.data_fim) {
          this.getResultadoAvaliacao()
          this.optionsArquivoGabarito.query.id_usuario_atividade = this.fastAtividadeAtual.id_usuario_atividade

          this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/prova/gabarito", "id_usuario_atividade=" + this.fastAtividadeAtual.id_usuario_atividade)
          .then(obj => {
            console.log("gabarito", obj);
            if (obj.length) this.fastPathGabarito = obj[0].path_gabarito
            else 
              this.fastPathGabarito = ""
          }).catch(e => {
            console.log(e);
            this.fastPathGabarito = ""
          })
          
        }
      } else if (atividade.tipo_atividade == "LV") {
        this.getFastLiveAtividade();
      } else if (atividade.tipo_atividade == "UP") {
        this.getFastUsuarioUploads();
      }
    },
    async voltaAtividade() {
      let atividadeAnterior = {};
      this.fastAtividades.forEach((a, index) => {
        if (a.sequencia + 1 == this.fastAtividadeAtual.sequencia) {
          atividadeAnterior = a;
        }
      });
      if (atividadeAnterior.id_usuario_atividade) {
        if (!this.fastAtividadeAtual.data_fim) {
          this.finalizaAtividadeClicada(atividadeAnterior);
        } else {
          this.iniciaAtividade(atividadeAnterior);
        }
      }
    },
    async proximaAtividade() {
      let finaliza = true;
      // Impede que atividade seja finaliza sem terminar o quiz
      if (this.fastAtividadeAtual.tipo_atividade == "QZ" && !this.fastAtividadeAtual.quiz_finalizado) {
        finaliza = false;
      }
      // Impede que avaliação seja finalizada (ela deve ser finalizada de forma manual)
      if (this.fastAtividadeAtual.tipo_atividade == "AV") {
        finaliza = false;
      }

      if (!this.fastAtividadeAtual.data_fim && finaliza) {
        let model = {
          fast_usuario_curso_aula_atividade: {
            id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          },
          id_plataforma: this.$route.params.id_plataforma,
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza", this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          // Seta uma data para o método verificaAulaFinalizada identificar que finalizou
          this.fastAtividadeAtual.data_fim = new Date();

          let atividadeProxima = {};
          this.fastAtividades.forEach((a) => {
            if (a.sequencia - 1 == this.fastAtividadeAtual.sequencia) {
              atividadeProxima = a;
            }
          });
          this.iniciaAtividade(atividadeProxima);
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      } else {
        let atividadeProxima = {};
        this.fastAtividades.forEach((a) => {
          if (a.sequencia - 1 == this.fastAtividadeAtual.sequencia) {
            atividadeProxima = a;
          }
        });
        this.iniciaAtividade(atividadeProxima);
      }
    },
    async finalizaAtividadeClicada(atividadeClicada) {
      let finaliza = true;
      // Impede que atividade seja finaliza sem terminar o quiz
      if (this.fastAtividadeAtual.tipo_atividade == "QZ" && !this.fastAtividadeAtual.quiz_finalizado) {
        finaliza = false;
      }
      // Impede que avaliação seja finalizada (ela deve ser finalizada de forma manual)
      if (this.fastAtividadeAtual.tipo_atividade == "AV") {
        finaliza = false;
      }
      // Só finaliza se não possuir data_fim
      if (!this.fastAtividadeAtual.data_fim && finaliza) {
        let model = {
          fast_usuario_curso_aula_atividade: {
            id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          },
          id_plataforma: this.$route.params.id_plataforma,
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza", this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          // Seta uma data para identificar que finalizou
          this.fastAtividadeAtual.data_fim = new Date();

          // Insere data de início
          this.iniciaAtividade(atividadeClicada);
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      } else {
        // Insere data de início
        this.iniciaAtividade(atividadeClicada);
      }
    },
    async finalizaAtividadeAtual() {
      let finaliza = true;
      // Impede que atividade seja finaliza sem terminar o quiz
      if (this.fastAtividadeAtual.tipo_atividade == "QZ" && !this.fastAtividadeAtual.quiz_finalizado) {
        finaliza = false;
      }
      // Impede que atividade seja finaliza sem terminar a avaliação
      if (this.fastAtividadeAtual.tipo_atividade == "AV" && !this.fastAtividadeAtual.avaliacao_finalizada) {
        finaliza = false;
      } else if (this.fastAtividadeAtual.tipo_atividade == "AV") {
        this.fastLoadingAvalicao = true;
      }
      // Só finaliza se não possuir data_fim
      if (!this.fastAtividadeAtual.data_fim && finaliza) {
        let model = {
          fast_usuario_curso_aula_atividade: {
            id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          },
          id_plataforma: this.$route.params.id_plataforma,
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza", this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            // Seta uma data para identificar que finalizou
            this.fastAtividadeAtual.data_fim = obj[0].data_fim;
            this.fastAtividadeAtual.tentativas_maxima = obj[0].tentativas_maxima;
            this.fastAtividadeAtual.count = obj[0].count;
          }

          if (this.fastAtividadeAtual.tipo_atividade == "AV") {
            
            this.verificaAvaliacoesPendentes("finalizou");
          }
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      }
    },
    acessaAtividadeLink(atividadeClicada) {
      if (!this.fastAtividadeAtual.data_fim) {
        this.finalizaAtividadeClicada(atividadeClicada);
      } else {
        this.iniciaAtividade(atividadeClicada);
      }
    },
    formataTipoAtividade(e) {
      switch (e) {
        case "RF":
          return "Arquivo";
          break;
        case "AD":
          return "Audio";
          break;
        case "VA":
          return "Video Aula";
          break;
        case "PS":
          return "Pesquisa";
          break;
        case "AV":
          return "Avaliação";
          break;
        case "SM":
          return "Simulado";
          break;
        case "QZ":
          return "Quiz";
          break;
        case "LV":
          return "Live";
          break;
        case "UP":
          return "Upload";
          break;
        default:
          return "Desconhecido";
      }
    },
    async iniciaAtividadeAtual() {
      let fast_usuario_curso_aula_atividade = {
        id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza_data_inicio_novo", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_curso_aula_atividade)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((a) => {
            if (a.id_usuario_atividade == this.fastAtividadeAtual.id_usuario_atividade) {
              this.fastAtividadeAtual.data_inicio = a.data_inicio;
              if (a.data_previsto_fim) {
                this.fastAtividadeAtual.data_previsto_fim = a.data_previsto_fim.split("T")[0] + " " + a.data_previsto_fim.split("T")[1].split(".")[0];

                // Hack para timer
                let startTimeEpoch = new Date(this.fastAtividadeAtual.data_previsto_fim).getTime() / 1000
                let d = new Date(0);
                d.setUTCSeconds(startTimeEpoch);
                let month = d.getMonth() + 1;
                let day = d.getDate();
                if (month < 10) month = "0" + month;
                if (day < 10) day = "0" + day;
                this.fastAtividadeAtual.data_previsto_fim =
                d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[1] + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[2];

                console.log("this.fastAtividadeAtual.data_previsto_fim", this.fastAtividadeAtual.data_previsto_fim)
              }
            }
          });
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async promiseIniciaAtividadeAtual() {
      return new Promise(async (resolve, reject) => {
        // Se for avaliação que não finalizou
        if (this.fastAtividadeAtual.tipo_atividade == "AV" && this.fastAtividadeAtual.data_inicio && !this.fastAtividadeAtual.data_fim) {
          resolve(true);
        } else {
          let fast_usuario_curso_aula_atividade = {
            id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          };
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza_data_inicio_novo", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_curso_aula_atividade)));
            let json = await resp.json();
            let obj = Array.from(json);
            if (obj.length) {
              obj.forEach((a) => {
                if (a.id_usuario_atividade == this.fastAtividadeAtual.id_usuario_atividade) {
                  this.fastAtividadeAtual.data_inicio = a.data_inicio;
                  if (a.data_previsto_fim) {
                    this.fastAtividadeAtual.data_previsto_fim = a.data_previsto_fim.split("T")[0] + " " + a.data_previsto_fim.split("T")[1].split(".")[0];

                    console.log("this.fastAtividadeAtual.data_previsto_fim", this.fastAtividadeAtual.data_previsto_fim)

                    // Hack para timer
                    let startTimeEpoch = new Date(this.fastAtividadeAtual.data_previsto_fim).getTime() / 1000
                    let d = new Date(0);
                    d.setUTCSeconds(startTimeEpoch);
                    let month = d.getMonth() + 1;
                    let day = d.getDate();
                    if (month < 10) month = "0" + month;
                    if (day < 10) day = "0" + day;
                    this.fastAtividadeAtual.data_previsto_fim =
                    d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[1] + ":" + this.fastAtividadeAtual.data_previsto_fim.split(':')[2];

                    console.log("this.fastAtividadeAtual.data_previsto_fim", this.fastAtividadeAtual.data_previsto_fim)
                  }
                }
              });

              resolve(true);
            } else {
              reject(false);
            }
          } catch (e) {
            this.exibeToasty(e, "error");
            reject(false);
          }
        }
      });
    },
    verificaAnteriorProximo() {
      // Desabilita botão anterior e próximo
      this.fastAtividadeAtual.atividade_antes = false;
      this.fastAtividadeAtual.atividade_depois = false;
      // Percorre as atividades da aula
      this.fastAtividades.forEach((a, index) => {
        // Encontra a atividade atual
        if (a.id_usuario_atividade == this.fastAtividadeAtual.id_usuario_atividade) {
          // Se existe atividades antes habilita botão anterior
          if (this.fastAtividades[index - 1]) this.fastAtividadeAtual.atividade_antes = true;
          // Se existe atividade depois habilita botão próximo
          if (this.fastAtividades[index + 1]) this.fastAtividadeAtual.atividade_depois = true;
        }
      });
    },
    retornaLetraAlternativa(index) {
      const letra = ["a", "b", "c", "d", "e", "f"];
      return letra[index];
    },
    retornaPathAtividade(tipo, referencia_atividade) {
      switch (tipo) {
        case "ppt":
          return "https://docs.google.com/gview?url=" + referencia_atividade + "&embedded=true";

          break;
        case "pptx":
          return "https://view.officeapps.live.com/op/embed.aspx?src=" + referencia_atividade;

          break;
        case "vimeo":
          return "https://player.vimeo.com/video/" + referencia_atividade.split("/")[3];
          break;
        default:
          break;
      }
    },
    // Quiz
    async getQuizQuestoes(atividade) {
      this.$toast.clear();
      this.$toast.open({
        message: "Buscando questões...",
        type: "info",
        duration: 60000,
        dismissible: true,
        position: "bottom-right",
      });
      if (!atividade.quiz) atividade.quiz = [];
      if (!atividade.quiz.length) {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/quiz/lista_novo?id_usuario_atividade=" + atividade.id_usuario_atividade, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
              return obj.find((a) => a.id_questao === id_questao);
            });

            questoesUnicas.forEach((a, index) => {
              obj.forEach((q, index2) => {
                if (a.id_questao == q.id_questao) {
                  if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                  questoesUnicas[index].alternativas.push(q);
                }
              });
            });
            // passando dentro de questoesUnicas para tirar o "&nbsp" de pergunta e alternativa
            questoesUnicas.forEach(a => {
              /*
              a.pergunta = a.pergunta.replaceAll("&nbsp;"," ");
                a.alternativas.forEach(q => {
                  q.alternativa = q.alternativa.replaceAll("&nbsp;", " ")
              })*/

              // Atualização (replaceAll é incompatível em sistemas mais antigos)
              a.pergunta = a.pergunta.replace(/&nbsp;/g, ' ');
                a.alternativas.forEach(q => {
                  q.alternativa = q.alternativa.replace(/&nbsp;/g, ' ')
              })
            })
            atividade.quiz = [];
            atividade.quiz = questoesUnicas;
            this.verificaQuestoesPendentes();
            this.$toast.clear();
          } else {
            this.getQuizQuestoesConcurseiro(atividade);
            this.$toast.clear();
          }
        } catch (e) {
          this.$toast.clear();
          this.exibeToasty(e, "error");
        }
      } else {
        this.$toast.clear();
      }
    },
    async respondeQuestaoQuiz(questao, alternativa) {
      questao.acertou = null;
      questao.resolvida = null;
      questao.alternativas.forEach((a) => {
        if (a.id_alternativa != alternativa.id_alternativa) a.opcao_escolhida = null;
        else a.opcao_escolhida = a.id_alternativa;
      });
      this.$toast.clear();
      this.$toast.open({
        message: "Salvando...",
        type: "info",
        duration: 60000,
        dismissible: true,
        position: "bottom-right",
      });
      let fast_usuario_aula_atividade_questao = {
        id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
        id_questao: questao.id_questao,
        opcao_escolhida: alternativa.id_alternativa,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/quiz/responde", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_aula_atividade_questao)));
        let json = await resp.json();
        let obj = Array.from(json);
        let acertou = false;
        obj.forEach((q) => {
          if (questao.id_questao == q.id_questao) {
            questao.acertou = q.acertou;
            if (q.acertou == "S") acertou = true;
            questao.resolvida = "S";
          }
        });

        this.$toast.clear();
        if (acertou) {
          this.$toast.open({
            message: "Parabéns! Você acertou a questão.",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        } else {
          this.$toast.open({
            message: "Questão incorreta! Tente novamente.",
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        }

        this.verificaQuestoesPendentes();
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    verificaQuestoesPendentes() {
      if (this.fastAtividadeAtual.quiz) {
        let totalQuestoes = this.fastAtividadeAtual.quiz.length;
        let totalQuestoesRespondida = 0;
        this.fastAtividadeAtual.quiz.forEach((q) => {
          if (q.resolvida == "S") totalQuestoesRespondida++;
        });

        if (totalQuestoes > totalQuestoesRespondida && totalQuestoesRespondida > 0) this.fastAtividadeAtual.questoes_pendentes = totalQuestoes - totalQuestoesRespondida;
        else if (totalQuestoes == totalQuestoesRespondida) {
          let acertos = 0;
          let erros = 0;
          this.fastAtividadeAtual.quiz.forEach((q) => {
            if (q.acertou == "S") acertos++;
            else erros++;
          });
          this.fastAtividadeAtual.quiz_finalizado = true;
          this.fastAtividadeAtual.questoes_pendentes = false;
          setTimeout(() => { this.chartDataQuiz = [acertos, erros] }, 1000);

          if (!this.fastAtividadeAtual.data_fim)
            this.promiseFinalizaAtividade()
              .then((e) => (this.fastAtividadeAtual.data_fim = new Date()))
              .catch((e) => this.exibeToasty(e, "error"));
        }
      }
    },
    async getQuizQuestoesConcurseiro(atividade) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/quiz/lista?id_usuario_atividade=" + atividade.id_usuario_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          let questao = {};
          let alternativas = [];
          obj.forEach((q, index) => {
            this.buscaPerguntaConcurseiro(q.id_questao).then((r) => {
              questao = {};
              this.buscaAlternativasConcurseiro(r[0].ID_QUESTAO).then((a) => {
                alternativas = [];
                a.forEach((e) => {
                  alternativas.push({
                    id_alternativa: e.ID_ALTERNATIVA,
                    alternativa: e.ALTERNATIVA,
                    correta: e.CORRETA,
                  });
                });
                questao = {
                  resolvida: q.resolvida,
                  acertou: q.acertou,
                  id_questao: r[0].ID_QUESTAO,
                  pergunta: r[0].PERGUNTA,
                  alternativas: alternativas,
                };
                this.fastAtividadeAtual.quiz.push(questao);
                if (index + 1 == obj.length) this.verificaQuestoesPendentes();
              });
            });
          });
        } else {
          this.$toast.open({
            message: "Nenhuma questão encontrada no QUIZ",
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async buscaPerguntaConcurseiro(id_questao) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiConcurseiro + "api/concurseiro/questoes/listaquestoeslivre?id_questao=" + id_questao, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    async buscaAlternativasConcurseiro(id_questao) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiConcurseiro + "api/concurseiro/alternativas/questao?id=" + id_questao, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    // Pesquisa de satisfação
    async getFastUsuarioPesquisa(atividade) {
      if (!atividade.pesquisa) atividade.pesquisa = [];
      if (!atividade.pesquisa.length) {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/pesquisa/lista_perguntas_respostas?id_usuario_atividade=" + atividade.id_usuario_atividade, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_pergunta))).map((id_pergunta) => {
              return obj.find((a) => a.id_pergunta === id_pergunta);
            });

            questoesUnicas.forEach((a, index) => {
              obj.forEach((q, index2) => {
                if (a.id_pergunta == q.id_pergunta) {
                  if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                  questoesUnicas[index].alternativas.push(q);
                }
              });
            });

            // Verifica qual questão já resolvidas
            questoesUnicas.forEach((p, index) => {
              let resolvida = false;
              if (p.alternativas.length) {
                p.alternativas.forEach((a) => {
                  if (a.opcao_escolhida == "S" || a.estrelas_escolhida) resolvida = true;
                });
              }
              p.resolvida = resolvida;
            });

            atividade.pesquisa = [];
            atividade.pesquisa = questoesUnicas;
            this.verificaPesquisasPendentes();
          }
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      }
    },
    async respondePesquisa(pergunta, alternativa) {
      pergunta.alternativas.forEach((a) => {
        if (a.id_resposta != alternativa.id_resposta) a.opcao_escolhida = null;
        else a.opcao_escolhida = a.id_resposta;
      });

      let model = {
        fast_usuario_curso_aula_atividade_pesquisa: {
          id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          tipo_pesquisa: pergunta.tipo_pesquisa,
          id_pergunta: pergunta.id_pergunta,
        },
        fast_usuario_curso_aula_atividade_pesquisa_resposta: {
          id_resposta: alternativa.id_resposta,
          estrelas_escolhida: alternativa.estrelas_escolhida,
        },
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/pesquisa/atualiza", this.fastAjaxOptions("POST", JSON.stringify(model)));
        let json = await resp.json();
        let obj = Array.from(json);
        pergunta.resolvida = true;
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Resposta salva com sucesso",
        });
        this.verificaPesquisasPendentes();
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    verificaPesquisasPendentes() {
      if (this.fastAtividadeAtual.pesquisa) {
        let totalQuestoes = this.fastAtividadeAtual.pesquisa.length;
        let totalQuestoesRespondida = 0;
        this.fastAtividadeAtual.pesquisa.forEach((q) => {
          if (q.resolvida) totalQuestoesRespondida++;
        });
        if (totalQuestoes > totalQuestoesRespondida && totalQuestoesRespondida > 0) this.fastAtividadeAtual.pesquisas_pendentes = totalQuestoes - totalQuestoesRespondida;
        else if (totalQuestoes == totalQuestoesRespondida) {
          console.log("totalQuestoes", totalQuestoes , "totalQuestoesRespondida", totalQuestoesRespondida)
          this.finalizaAtividadeAtual();
          this.fastAtividadeAtual.pesquisas_pendentes = false;
          this.fastAtividadeAtual.pesquisa_finalizada = true;
          this.hideModal("modalPesquisa");
        }
      }
    },
    // Avaliação
    async promiseGetAvaliacaoQuestoes() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/prova/lista_perguntas?id_usuario_atividade=" + this.fastAtividadeAtual.id_usuario_atividade, this.fastAjaxOptions("GET"));
          let json = await resp.json();
            
            
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    async respondeQuestaoAvaliacao(questao, alternativa) {
      questao.alternativas.forEach((a) => {
        if (a.id_alternativa != alternativa.id_alternativa) a.opcao_escolhida = null;
        else a.opcao_escolhida = a.id_alternativa;
      });

      let fast_usuario_aula_atividade_prova = {
        id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
        id_questao: questao.id_questao,
        opcao_escolhida: alternativa.id_alternativa,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/prova/responde", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_aula_atividade_prova)));
        let json = await resp.json();
        let obj = Array.from(json);
        //obj.forEach((q) => {
          //if (questao.id_questao == q.id_questao) {
            questao.resolvida = "S";
          //}
        //});
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Resposta salva com sucesso",
        });
        this.verificaAvaliacoesPendentes("respondeu");
      } catch (e) {
        this.exibeToasty(e, "error");
        console.log(e)
      }
    },
    verificaAvaliacoesPendentes(acao) {
      if (this.fastAtividadeAtual.avaliacao) {
        let totalQuestoes = this.fastAtividadeAtual.avaliacao.length;
        let totalQuestoesRespondida = 0;
        this.fastAtividadeAtual.avaliacao.forEach((q) => {
          if (q.resolvida == "S") totalQuestoesRespondida++;
        });

        switch (acao) {
          case "continuar":
            // Questões pendentes e avaliação não finalizou
            /*if (
              totalQuestoes > totalQuestoesRespondida &&
              totalQuestoesRespondida > 0 && !this.fastAtividadeAtual.data_fim
            ) {*/
            this.fastAtividadeAtual.avaliacoes_pendentes = totalQuestoes - totalQuestoesRespondida;

            if (totalQuestoes == totalQuestoesRespondida) {
              this.fastAtividadeAtual.avaliacao_finalizada = true;
              // Se existem questões pendentes
            } else if (totalQuestoes > totalQuestoesRespondida && totalQuestoesRespondida > 0) {
              this.fastAtividadeAtual.avaliacao_finalizada = false;
            }
            
            this.showModal("modalAvaliacao");

            if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00") {
              //console.log('if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00")')
              this.verificaTempo();
            }
            //}
            break;

          case "respondeu":
            // Se todas as questões foram respondidas e avaliação não finalizou
            if (totalQuestoes == totalQuestoesRespondida) {
              this.fastAtividadeAtual.avaliacao_finalizada = true;
              this.fastAtividadeAtual.avaliacoes_pendentes = false;
              this.hideModal("modalAvaliacao");
              // Se existem questões pendentes
            } else if (totalQuestoes > totalQuestoesRespondida && totalQuestoesRespondida > 0) {
              this.fastAtividadeAtual.avaliacao_finalizada = false;
              this.fastAtividadeAtual.avaliacoes_pendentes = true;
            }
            break;
          case "finalizou":
            this.fastAtividadeAtual.avaliacao_finalizada = true;
            this.fastAtividadeAtual.avaliacoes_pendentes = false;
            this.fastAtividadeAtual.refazendo_avaliacao = false;
            this.getResultadoAvaliacao();
            this.hideModal("modalAvaliacao");
            break;
          default:
            break;
        }
      } else {
        this.fastFinalizandoAvaliacao = false;
      }
    },
    async iniciarAvaliacao(acao) {
      this.chartAvaliacaoVisivel = false;

      console.log("iniciarAvaliacao", "Acao ->" + acao)

      this.promiseIniciaAtividadeAtual()
        .then(() => {
          this.exibeToasty("Buscando questões", "info");
          this.fastLoadingAvalicao = true;
        })
        .then(() => {
          return this.promiseGetAvaliacaoQuestoes();
        })
        .then((obj) => {
          
          if (obj.length) {
            // Separa as questões e alternativas
            const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
              return obj.find((a) => a.id_questao === id_questao);
            });
            questoesUnicas.forEach((a, index) => {
              obj.forEach((q, index2) => {
                if (a.id_questao == q.id_questao) {
                  if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                  questoesUnicas[index].alternativas.push(q);
                }
              });
            });
            // passando dentro de questoesUnicas para tirar o "&nbsp" de pergunta e alternativa
            questoesUnicas.forEach(a => {
              /*
              a.pergunta = a.pergunta.replaceAll("&nbsp;"," ");
                a.alternativas.forEach(q => {
                  q.alternativa = q.alternativa.replaceAll("&nbsp;", " ")
              })
              */

              // Atualização (replaceAll é incompatível em sistemas mais antigos)
              a.pergunta = a.pergunta.replace(/&nbsp;/g, ' ');
                a.alternativas.forEach(q => {
                  q.alternativa = q.alternativa.replace(/&nbsp;/g, ' ')
              })
              
            })
            this.fastAtividadeAtual.avaliacao = questoesUnicas;
            

            this.$toast.clear();
            this.fastLoadingAvalicao = false;

            switch (acao) {
              case "iniciar":
                this.showModal("modalAvaliacao");
                if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00") {
                  console.log('if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00")')
                  this.verificaTempo();
                }
                break;
              case "continuar":
                // Verifica se as questões já foram respondidas
                this.fastAtividadeAtual.avaliacoes_pendentes = true;
                this.verificaAvaliacoesPendentes(acao);
                break;
              case "refazer":
                this.fastAtividadeAtual.refazendo_avaliacao = true;
                console.log("Refazendo", this.fastAtividadeAtual);
                this.fastAtividadeAtual.avaliacao.forEach((q) => {
                  q.resolvida = null;
                  q.acertou = null;
                  q.alternativas.forEach((a) => {
                    a.resolvida = null;
                    a.opcao_escolhida = null;
                  });
                });
                // Verifica se as questões já foram respondidas
                //this.verificaAvaliacoesPendentes(acao);
                this.showModal("modalAvaliacao");
                if (!this.fastAvaliacaoTempoIniciada && this.fastAtividadeAtual.tempo_atividade && this.fastAtividadeAtual.tempo_atividade != "00:00:00") this.verificaTempo();
                break;
              default:
                break;
            }
          } else {
            this.exibeToasty("Nenhuma questão encontrada", "error")
          }
        });
    },
    async getResultadoAvaliacao() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/prova/lista?id_usuario_atividade=" + this.fastAtividadeAtual.id_usuario_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          let acertos = 0;
          let erros = 0;
          let nota = 0;
          let peso_total = 0;
          obj.forEach((q) => {
            peso_total += q.questao_peso;
            if (q.acertou != "S") {
              erros++;
            } else {
              nota += q.questao_peso;
              acertos++;
            }
          });
          this.chartAvaliacaoVisivel = true;
          this.fastPesoAvaliacao = peso_total ? parseFloat(peso_total).toFixed(2) : 0
          this.fastNotaAvaliacao = nota ? parseFloat(nota).toFixed(2) : 0
          this.chartDataProva = [acertos, erros];
          this.chartDataProvaPeso = [nota, peso_total - nota];

          this.fastLoadingAvalicao = false;
        }
      } catch (e) {
        this.exibeToasty(e, "error");
        console.log(e)
        this.fastLoadingAvalicao = false;
        this.fastLoadingAvalicao = false;
      }
    },
    async getPesoAvaliacao() {
      let prova_peso = 0;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/prova/lista_peso?id_usuario_atividade=" + this.fastAtividadeAtual.id_usuario_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          prova_peso = obj[0].prova_peso;
        }
        this.fastAtividadeAtual.prova_peso = prova_peso;
      } catch (e) {
        this.exibeToasty(e, "error");
        console.log(e)
      }
    },
    async verificaTempo() {
      if (!this.fastAtividadeAtual.avaliacao_finalizada && this.fastAtividadeAtual.data_previsto_fim) {
        this.fastAvaliacaoTempoIniciada = true;
        let dataTermino = new Date(this.fastAtividadeAtual.data_previsto_fim);
        dataTermino.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
        // Pegar hora no servidor
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/lista_hora", this.fastAjaxOptions("GET"));
          let obj = await resp.json();
          //let obj = JSON.parse(json);
          let dataAtual = new Date(obj.dataAtual);
          dataAtual.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })

          console.log("dataAtual", dataAtual, "dataTermino", dataTermino)

          if (dataAtual > dataTermino) {
            this.exibeToasty("Tempo esgotado", "error");

            this.tempoEsgotado();
          } else {
            console.log("Tempo disponível...");
            setTimeout(() => {
              this.verificaTempo();
            }, 10000);
          }
        } catch (e) {
          this.exibeToasty(e, "error");
          console.log(e)
        }
      }      
    },
    async tempoEsgotado() {
      console.log("tempoEsgotado")
      if (this.fastAtividadeAtual.tipo_atividade == "AV") {
        this.fastAtividadeAtual.avaliacao_finalizada = true;
        let model = {
          fast_usuario_curso_aula_atividade: {
            id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          },
          id_plataforma: this.$route.params.id_plataforma,
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza", this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          // Seta uma data para identificar que finalizou
          this.fastAtividadeAtual.data_fim = new Date();
          this.getResultadoAvaliacao();
          this.hideModal("modalAvaliacao");
        } catch (e) {
          this.exibeToasty(e, "error");
          console.log(e)
        }
      }
    },
    formataTempoLimite() {
      let horas = parseInt(this.fastAtividadeAtual.tempo_atividade.split(":")[0]);
      let minutos = parseInt(this.fastAtividadeAtual.tempo_atividade.split(":")[1]);
      let tempo_limite = "";
      if (horas) {
        if (horas == 1) tempo_limite = "1 hora";
        else tempo_limite = horas + " horas";
        if (minutos) {
          tempo_limite += " e " + minutos + " minutos";
        }
      } else if (minutos) {
        tempo_limite = minutos + " minutos";
      } else {
        tempo_limite = "Ilimitado";
      }

      return tempo_limite;
    },
    async finalizaAvaliacaoTentativa() {
      if ((this.fastAtividadeAtual.count < this.fastAtividadeAtual.tentativas_maxima || !this.fastAtividadeAtual.tentativas_maxima || this.fastAtividadeAtual.tentativas_maxima == "0") && !this.fastLoadingAvalicao) {
        console.log("Finalizando avaliação")
        this.fastLoadingAvalicao = true;
        let model = {
          fast_usuario_curso_aula_atividade: {
            id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          },
          id_plataforma: this.$route.params.id_plataforma,
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza", this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            // Seta uma data para identificar que finalizou
            this.fastAtividadeAtual.data_fim = obj[0].data_fim;
            this.fastAtividadeAtual.tentativas_maxima = obj[0].tentativas_maxima;
            this.fastAtividadeAtual.count = obj[0].count;
          }

          this.verificaAvaliacoesPendentes("finalizou");
        } catch (e) {
          this.exibeToasty(e, "error");
          console.log(e)
          this.fastLoadingAvalicao = false;
        }
      }
    },
    provaDownloadGabarito(){
      
      let erros = []
      if (!this.fastAtividadeAtual.id_usuario_atividade) erros.push("Atividade não identificada")
      if (this.fastAtividadeAtual.tipo_atividade != "AV") erros.push("A atividade atual não é uma prova")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
        console.log('erros', erros)
      } else {
        this.exibeToasty("Buscando gabarito", "info")
        this.promiseGetAvaliacaoQuestoes()
        .then((obj) => {          
          if (obj.length) {
            // Separa as questões e alternativas
            const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
              return obj.find((a) => a.id_questao === id_questao);
            });
            questoesUnicas.forEach((a, index) => {
              obj.forEach((q, index2) => {
                if (a.id_questao == q.id_questao) {
                  if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                  questoesUnicas[index].alternativas.push(q);
                }
              });
            });
            // passando dentro de questoesUnicas para tirar o "&nbsp" de pergunta e alternativa
            questoesUnicas.forEach(a => {            
              // Atualização (replaceAll é incompatível em sistemas mais antigos)
              a.pergunta = a.pergunta.replace(/&nbsp;/g, ' ');
                a.alternativas.forEach(q => {
                  q.alternativa = q.alternativa.replace(/&nbsp;/g, ' ')
                })              
              })
              this.fastAtividadeAtual.avaliacao = questoesUnicas
              this.$toast.clear()            
              console.log("this.fastAtividadeAtual.avaliacao", this.fastAtividadeAtual.avaliacao)
              console.log("this.fastAtividadeAtual", this.fastAtividadeAtual)

              let gabarito = ""
              this.fastAtividadeAtual.avaliacao.forEach(q => {
                  gabarito += `<tr class="text-center">
                  <td>#${q.id_questao}</td>
                  <td>#${q.opcao_escolhida}</td>
                  </tr>`
              })

              this.htmlProvaGabarito = `
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <h1 class="text-info">${this.nome_plataforma}</h1>
                    </div>
                    <div class="col-6 text-right">                      
                      <h5 class="text-info">Data da realização: ${this.formataDataT(this.fastAtividadeAtual.data_fim)}</h5>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <h4>Gabarito - ${this.fastCursoPlataforma.nome_curso ? this.fastCursoPlataforma.nome_curso : this.fastCursoPlataforma.nome_curso_mae}</h4>
                    </div>
                    <div class="col-12 table-responsive">
                      <table class="table table-sm table-bordered">
                        <tr class="text-center">
                          <th class="pt-0 pb-0">Questão</th>
                          <th class="pt-0 pb-0">Alternativa</th>
                        </tr>
                        ${gabarito}              
                      </table>
                    </div>
                  </div>
                  <div class="row mt-4 text-center">
                      <div class="col-12">
                        _____________________________________________________
                      </div>
                      <div class="col-12">
                        <h5>Assinatura do aluno</h5>
                      </div>
                    </div>
                </div>
              `;
              this.$refs.html2Pdf.generatePdf();
/*
              this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/logo_plataforma_tobase64",`url=${this.logo_plataforma}`)
                .then((res) => {
                  console.log("logo_plataforma_tobase64", res)
                    this.htmlProvaGabarito = `
                    <div class="container">
                      <div class="row">
                        <div class="col-6">
                          <img src="${res}" style="max-width: 200px">
                        </div>
                        <div class="col-6 text-right">
                          <h5 class="text-info">Gabarito da prova ${this.fastAtividadeAtual.id_usuario_atividade}</h5>
                        </div>
                      </div>
                    </div>
                  `;
                  this.$refs.html2Pdf.generatePdf();
                })
                .catch((e) => {
                  this.htmlProvaGabarito = `
                    <div class="container">
                      <div class="row">
                        <div class="col-6">
                          <img src="" style="max-width: 200px">
                        </div>
                        <div class="col-6 text-right">
                          <h5 class="text-info">Gabarito da prova ${this.fastAtividadeAtual.id_usuario_atividade}</h5>
                        </div>
                      </div>
                    </div>
                  `;
                  this.$refs.html2Pdf.generatePdf();
                });
                */


            } else {
              this.exibeToasty("Nenhuma questão encontrada", "error")
            }
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          console.log(e)
        })
      }
    },
    fileSuccessGabarito(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.exibeToasty("Gabarito enviado com sucesso", "success")
        this.fastPathGabarito = result.url
      } else {
        this.exibeToasty("Erro ao fazer upload", "error")
      }
    },
    // Informações da aula
    async exibeAulasCurso() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula/lista?id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        this.exibeAulasCursoPendentes(obj);
      } catch (e) {
        this.exibeToasty(e, "error");
        this.fastCursoAulasLoading = false
      }
    },
    async exibeAulasCursoPendentes(aulas) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula/lista_pendentes_curso?id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          if (aulas.length) {
            let aulaTotal = obj.concat(aulas);
            this.fastCursoAulas = aulaTotal.sort(this.sortArrayAulas);
          } else {
            this.fastCursoAulas = obj;
          }
        } else {
          if (aulas.length) {
            this.fastCursoAulas = aulas;
          } else {
            this.fastCursoAulas = [];
          }
        }

        this.fastCursoAulas.some((e, index) => {
          if (e.id_aula == this.fastAtividadeAtual.id_aula) {
            // Se existir aula posterior
            if (this.fastCursoAulas[index + 1]) this.fastAulaPosterior = this.fastCursoAulas[index + 1];
            // Se existir aula anterior
            if (this.fastCursoAulas[index - 1]) this.fastAulaAnterior = this.fastCursoAulas[index - 1];
          }

          e.name = e.sequencia + " - " + e.titulo_aula;
          e.id = e.id_aula;
          e.id_aula = e.id_aula;
          e.status = e.status;

          this.fastAulaAtual.id_aula == e.id_aula ? (e.classe = "font-weight-bold") : (e.classe = "text-secondary");
          e.isLeaf = false;
          e.addLeafNodeDisabled = true;
        });

        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade?id_atividade=&id_curso=" + this.$route.params.id_curso + "&id_aula=", this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let objAtividades = Array.from(json);
          if (objAtividades.length > 0) {
            objAtividades = objAtividades.filter((a) => {
              if (a.publicada) return true;
            });
            objAtividades.forEach((e) => {
              e.name = e.sequencia + " - " + e.titulo_atividade;
              e.id = e.id_atividade;
              e.id_aula = e.id_aula;
              e.id_atividade = e.id_atividade;

              this.fastAulaAtual.id_aula == e.id_aula ? (e.classe = "font-weight-bold") : (e.classe = "text-secondary");
              e.isLeaf = false;
              e.addLeafNodeDisabled = true;

              
            });
            this.fastCursoAulas.forEach((aula, index) => {
              objAtividades.forEach((atividade, index2) => {
                if (aula.id_aula == atividade.id_aula) {
                  atividade.status = aula.status;
                  if (!this.fastCursoAulas[index].children) {
                    this.fastCursoAulas[index].children = [];
                  }
                  this.fastCursoAulas[index].children.push(atividade);
                }
              });
            });
            console.log(`this.fastCursoAulas`, this.fastCursoAulas)
          }
          if (!this.isMobile()) this.treeAulas = new Tree(this.fastCursoAulas);
          this.$store.state.fastCarregando = false;

          this.fastCursoAulasLoading = false
        } catch (e) {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
          this.fastCursoAulasLoading = false
        }
      } catch (e) {
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
        this.fastCursoAulasLoading = false
      }
    },
    sortArrayAulas(a, b) {
      const sequenciaA = a.sequencia;
      const sequenciaB = b.sequencia;

      let comparison = 0;
      if (sequenciaA > sequenciaB) {
        comparison = 1;
      } else if (sequenciaA < sequenciaB) {
        comparison = -1;
      }
      return comparison;
    },
    async acessarAula(obj) {
      //this.fastAtividadeAtual.id_usuario_atividade = 0
      //this.$store.state.fastCarregando = true;
      /* Antes de direcionar para aula tem que se certificar que:
        1 - A atividade atual está finalizada e se for necessário finalizar
        2 - Verificar se todas as atividades dessa aula atual foram finalizadas para então finalizar a aula
        3 - Verificar se a próxima aula a ser acessada já foi inserida na tabela do usuário
      */

      // Verificar aqui se a atividade foi finalizada
      let finaliza = true;
      // Impede que atividade seja finaliza sem terminar o quiz
      if (this.fastAtividadeAtual.tipo_atividade == "QZ" && !this.fastAtividadeAtual.quiz_finalizado) {
        finaliza = false;
      }
      // Impede que avaliação seja finalizada (ela deve ser finalizada de forma manual)
      if (this.fastAtividadeAtual.tipo_atividade == "AV") {
        finaliza = false;
      }
      // Atividade atual não foi finalizada e pode ser finalizada?
      if (!this.fastAtividadeAtual.data_fim && finaliza) {
        console.info("Finalizando atividade...");
        // Finaliza atividade atual
        this.promiseFinalizaAtividade()
          .then((e) => {
            console.info("Atividade finalizada...");
            console.info("Verificando aula atual...");
            // Verifica se aula atual deve ser finalizada também
            this.promiseVerificaAulaFinalizada()
              .then((e) => {
                // Se existir status o aluno já acessou essa aula
                if (obj.status) {
                  console.info("Próxima aula já foi acessada...");
                  // Se o usuário clicou em uma atividade                  
                  if (obj.id_atividade) {
                    if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
                      this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + obj.id_aula + "?atividade=" + obj.id_atividade + "&turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
                    } else {
                      this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + obj.id_aula + "?atividade=" + obj.id_atividade);
                    }
                    
                  } else {
                    if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
                      this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + obj.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
                    } else {
                      this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + obj.id_aula);
                    }
                    
                  }
                } else {
                  // Será necessário inserir a aula na tabela do aluno
                  this.acessaNovaAula(obj);
                  console.info("Será necessário inserir a aula na tabela do aluno...");
                }
              })
              .catch((e) => {
                this.exibeToasty(e, "error");
              });
          })
          .catch((e) => {
            this.$toast.open({
              message: "Erro ao finalizar atividade",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
          });
      } else {
        console.info("A atividade atual já foi finalizada ou não pode ser finalizada");
        console.info("Verificando aula atual...");
        // Verifica se aula atual deve ser finalizada também
        this.promiseVerificaAulaFinalizada()
          .then((e) => {
            // Se existir status o aluno já acessou essa aula
            if (obj.status) {
              console.info("Próxima aula já foi acessada...");
              // Se o usuário clicou em uma atividade
              if (obj.id_atividade) {
                if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
                  this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + obj.id_aula + "?atividade=" + obj.id_atividade + "&turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
                } else {
                  this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + obj.id_aula + "?atividade=" + obj.id_atividade);
                }                
              } else {
                if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
                  this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + obj.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
                } else {
                  this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + obj.id_aula);
                }                
              }
            } else {
              // Será necessário inserir a aula na tabela do aluno
              this.acessaNovaAula(obj);
              console.info("Será necessário inserir a aula na tabela do aluno...");
            }
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
          });
      }
    },
    async acessaNovaAula(aula) {
      let fast_usuario_curso_aula = {
        id_aula: aula.id_aula,
        id_plataforma: this.$route.params.id_plataforma,
        fast_usuario_curso: {
          id_curso: this.$route.params.id_curso,
        },
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula/insere_aula", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_curso_aula)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          if (aula.id_atividade) {
            if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
              this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + aula.id_aula + "?atividade=" + aula.id_atividade + "&turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
            } else {
              this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + aula.id_aula + "?atividade=" + aula.id_atividade);
            }            
          } else {
            if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
              this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + aula.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
            } else {
              this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "/aula/" + aula.id_aula);
            }            
          }
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Erro ao iniciar aula",
          });
        }
      } catch (e) {
        this.$store.state.fastCarregando = false;
        this.exibeToasty(e, "error");
      }
    },
    async sairAula() {
      if (!this.fastAtividadeAtual.data_fim) {
        let finaliza = true;
        // Impede que atividade seja finaliza sem terminar o quiz
        if (this.fastAtividadeAtual.tipo_atividade == "QZ" && !this.fastAtividadeAtual.quiz_finalizado) {
          finaliza = false;
        }
        // Impede que atividade seja finaliza sem terminar o quiz
        if (this.fastAtividadeAtual.tipo_atividade == "AV" && !this.fastAtividadeAtual.avaliacao_finalizada) {
          finaliza = false;
        }
        // Só finaliza se não possuir data_fim
        if (!this.fastAtividadeAtual.data_fim && finaliza) {
          // loading
          //this.$store.state.fastCarregando = true;
          let model = {
            fast_usuario_curso_aula_atividade: {
              id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
            },
            id_plataforma: this.$route.params.id_plataforma,
          };
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza", this.fastAjaxOptions("POST", JSON.stringify(model)));
            let json = await resp.json();
            // Seta uma data para o método verificaAulaFinalizada identificar que finalizou
            this.fastAtividadeAtual.data_fim = new Date();
            this.verificaAulaFinalizada();
          } catch (e) {
            this.exibeToasty(e, "error");
          }
        } else {
          this.verificaAulaFinalizada();
        }
      } else {
        this.verificaAulaFinalizada();
      }
    },
    async verificaAulaFinalizada() {
      let atv_finalizadas = 0;
      this.fastAtividades.forEach((a) => {
        if (a.data_fim) atv_finalizadas++;
      });
      if (atv_finalizadas == this.fastAtividades.length) {
        // loading
        //this.$store.state.fastCarregando = true;
        let fast_usuario_curso_aula = {
          id_aula: this.fastAtividadeAtual.id_aula,
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula/finaliza", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_curso_aula)));
          let json = await resp.json();
          if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
            //this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
            window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula'))))
          } else {
            //this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso);
            window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso
          }          
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      } else {
        if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
          //this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
          window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula'))))
        } else {
          //this.$router.push("/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso);
          window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/aluno/meus-cursos/curso/" + this.$route.params.id_curso
        }        
      }
    },
    async promiseVerificaAulaFinalizada() {
      return new Promise(async (resolve, reject) => {
        let atv_finalizadas = 0;
        this.fastAtividades.forEach((a) => {
          if (a.data_fim) atv_finalizadas++;
        });
        if (atv_finalizadas == this.fastAtividades.length) {
          let fast_usuario_curso_aula = {
            id_aula: this.fastAtividadeAtual.id_aula,
          };
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula/finaliza", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_curso_aula)));
            let json = await resp.json();

            resolve(1);
          } catch (e) {
            this.exibeToasty(e, "error");
            reject(0);
          }
        } else {
          resolve(1);
        }
      });
    },
    async promiseFinalizaAtividade() {
      return new Promise(async (resolve, reject) => {
        let model = {
          fast_usuario_curso_aula_atividade: {
            id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          },
          id_plataforma: this.$route.params.id_plataforma,
        };
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/atualiza", this.fastAjaxOptions("POST", JSON.stringify(model)));
          let json = await resp.json();
          resolve(1);
        } catch (e) {
          this.exibeToasty(e, "error");
          reject(0);
        }
      });
    },
    async getFastAulaDetalhes() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula/lista?id_aula=" + this.$route.params.id_aula + "&id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj[0].professor = [];
          this.fastAulaAtual = obj[0];
          if (obj[0].id_professor) this.getFastAulaProfessor(obj[0].id_professor);
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async getFastAulaProfessor(id_professor) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula/exibe_professor?id_professor=" + id_professor, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastAulaAtual.professor = obj[0];
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async getFastPlataformaMedalhas() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_medalha/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataformaMedalhas = obj;
          this.getFastUsuarioMedalhas();
        } else {
          this.fastPlataformaMedalhas = [];
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async getFastUsuarioMedalhas() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_medalha/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((m) => {
            this.fastPlataformaMedalhas.forEach((p) => {
              if (m.nivel_medalha == p.nivel_medalha) p.quantidade = m.qtd;
            });
          });
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    // Anotações
    async getAnotacoesAtividade() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade_anotacao/lista?id_usuario_atividade=" + this.fastAtividadeAtual.id_usuario_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastAnotacoes = obj;
        } else {
          this.fastAnotacoes = [];
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async insereAnotacaoAtividade() {
      if (this.fastAnotacaoAtual.anotacao) {
        let acao = "insere";
        if (this.fastAnotacaoAtual.id_usuario_atividade_anotacao) acao = "atualiza";
        this.fastAnotacaoAtual.id_usuario_atividade = this.fastAtividadeAtual.id_usuario_atividade;
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade_anotacao/" + acao, this.fastAjaxOptions("POST", JSON.stringify(this.fastAnotacaoAtual)));

          let json = await resp.json();
          let obj = Array.from(json);

          this.fastAnotacaoAtual = {
            id_usuario_atividade_anotacao: 0,
            id_usuario_atividade: "",
            anotacao: "",
            data_hora: null,
            ativa: false,
          };

          this.getAnotacoesAtividade();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Anotação salva com sucesso",
          });
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      }
    },
    editarAnotacao(anotacao) {
      this.fastAnotacaoAtual = anotacao;
      this.fastAnotacaoAtual.ativa = true;
    },
    async excluiAnotacoesAtividade(anotacao) {
      const fast_usuario_curso_aula_atividade_anotacao = {
        id_usuario_atividade_anotacao: anotacao.id_usuario_atividade_anotacao,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade_anotacao/exclui", this.fastAjaxOptions("POST", JSON.stringify(fast_usuario_curso_aula_atividade_anotacao)));

        let json = await resp.json();
        let obj = Array.from(json);

        this.fastAnotacaoAtual = {
          id_usuario_atividade_anotacao: "",
          id_usuario_atividade: "",
          anotacao: "",
          data_hora: "",
          ativa: false,
        };

        this.getAnotacoesAtividade();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Anotação excluída com sucesso",
        });
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    // Ranking
    async getFastRanking() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_ranking/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastRanking = obj;
        } else {
          this.fastRanking = [];
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async getFastRankingParametros() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_ranking_parametros/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastRankingParametros = obj[0];
        } else {
          this.fastRankingParametros = [];
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    // Cadernos
    async getCadernosUsuario() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_caderno_usuario/lista", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.cadernosTotal = obj;
          this.cadernosFiltro = obj;
          obj.forEach((c) => {
            this.getAtividadesCadernosUsuario(c.id_caderno);
          });
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async cadastraNovoCaderno() {
      if (this.fastCadernoNovo.nome_caderno) {
        let acao = "insere";
        if (this.fastCadernoNovo.id_caderno) acao = "atualiza";

        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_caderno_usuario/" + acao, this.fastAjaxOptions("POST", JSON.stringify(this.fastCadernoNovo)));

          let json = await resp.json();
          let obj = Array.from(json);

          this.fastCadernoNovo = {
            id_caderno: 0,
            nome_caderno: "",
            id_usuario: 0,
            datacriacao: null,
            data_alteracao: null,
          };

          this.getCadernosUsuario();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Caderno salvo com sucesso",
          });
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      }
    },
    async getAtividadesCadernosUsuario(id_caderno) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_caderno_usuario_atividade/lista?id_caderno=" + id_caderno, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.cadernosTotal.forEach((c) => {
            if (c.id_caderno == id_caderno) {
              c.atividades = [];
              c.atividades = obj;
            }
          });
        }
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    async adicionaAtividadeCaderno(caderno) {
      let fast_caderno_usuario_atividade = {
        id_caderno: caderno.id_caderno,
        id_curso: this.$route.params.id_curso,
        id_aula: this.$route.params.id_aula,
        detalhe_atividade: this.fastAtividadeAtual.detalhe_atividade,
        titulo_atividade: this.fastAtividadeAtual.titulo_atividade,
        tipo_atividade: this.fastAtividadeAtual.tipo_atividade,
        referencia_atividade: this.fastAtividadeAtual.referencia_atividade,
        id_video: 0,
        id_apostila: 0,
        id_questao: 0,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_caderno_usuario_atividade/insere", this.fastAjaxOptions("POST", JSON.stringify(fast_caderno_usuario_atividade)));

        let json = await resp.json();
        let obj = Array.from(json);
        this.hideModal("modalCadernos");
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Atividade salva no caderno",
        });
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    // Live
    async getFastLiveAtividade() {
      if (this.fastAtividadeAtual.referencia_atividade) {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_live/lista_usuario_nova?id_live=" + this.fastAtividadeAtual.referencia_atividade, this.fastAjaxOptions("GET"));
          let obj = await resp.json();
          if (obj.length) {
            let lives = Array.from(new Set(obj.map((a) => a.id_live))).map((id_live) => {
              return obj.find((a) => a.id_live === id_live);
            });
            lives.forEach((live, index) => {
              if (!live.participantes) live.participantes = [];
              obj.forEach((p, index2) => {
                if (live.id_live == p.id_live) live.participantes.push(p.id_usuario);
              });
            });

            this.fastLive = lives[0];
          } else {
            this.$toast.open({
              message: "Nenhuma live encontrada",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
          }

          this.$store.state.fastCarregando = false;
        } catch (e) {
          this.exibeToasty(e, "error");
        }
      } else {
        this.$toast.open({
          message: "A live ainda não foi agendada",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      }
    },
    // Uploads
    async getFastUsuarioUploads() {
      this.optionsArquivo.query.id_atividade = this.fastAtividadeAtual.id_atividade;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_aula_atividade_upload/lista?id_usuario_atividade=" + this.fastAtividadeAtual.id_usuario_atividade, this.fastAjaxOptions("GET"));
        let obj = await resp.json();
        if (obj.length) {
          const uploads = Array.from(new Set(obj.map((a) => a.id_usuario_upload))).map((id_usuario_upload) => {
            return obj.find((a) => a.id_usuario_upload === id_usuario_upload);
          });

          let uploads_filter = [];

          obj.forEach((u, index) => {
            uploads.forEach((f, index) => {
              if (u.id_usuario_upload == f.id_usuario_upload) {
                if (!f.anexos) f.anexos = [];
                f.anexos.push(u);
              }
            });
          });

          this.fastAtividadeAtual.uploads = uploads;
        }

        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.exibeToasty(e, "error");
      }
    },
    fileValidation(e) {
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.fastUsuarioUploadNovo.paths.push(result.url);
      }
    },
    async insereFastUsuarioUpload() {
      //this.$store.state.fastCarregando = true;
      if (!this.fastUsuarioUploadNovo.html_texto) this.fastUsuarioUploadNovo.html_texto = "Sem detalhes";

      let model = {
        fast_usuario_aula_atividade_upload: {
          id_usuario_atividade: this.fastAtividadeAtual.id_usuario_atividade,
          html_texto: this.fastUsuarioUploadNovo.html_texto,
        },
        paths: this.fastUsuarioUploadNovo.paths.join(),
      };

      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_aula_atividade_upload/insere", this.fastAjaxOptions("POST", JSON.stringify(model)));
        let obj = await resp.json();
        this.$toast.open({
          message: "Atividade enviada com sucesso",
          type: "success",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        this.fastUsuarioUploadNovo = {
          id_usuario_atividade: "",
          html_texto: "",
          paths: [],
        };
        this.hideModal("modalUpload");
        this.getFastUsuarioUploads();
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
      }
    },
    exibeModalUploadEnvios(usuario) {
      this.fastUsuarioUploadVisualizar = usuario;
      if (usuario.corrigido == "S") {
        //this.$store.state.fastCarregando = true;
        this.getFastUsuarioUploadsProfessor(usuario.id_usuario_upload)
          .then((e) => {
            this.$store.state.fastCarregando = false;
            this.fastUsuarioUploadProfessor = e[0];
            this.showModal("modalUploadEnvios");
          })
          .catch((e) => {
            this.$store.state.fastCarregando = false;
            this.exibeToasty(e, "error");
          });
      } else {
        this.fastUsuarioUploadProfessor = {
          id_usuario_upload: "",
          data_hora: "",
          html_texto: "",
          anexos: [],
        };
        this.showModal("modalUploadEnvios");
      }
    },
    async getFastUsuarioUploadsProfessor(id_usuario_upload) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_aula_atividade_upload_professor/lista_respostas?id_usuario_upload=" + id_usuario_upload + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
          let obj = await resp.json();
          if (obj.length) {
            const uploads = Array.from(new Set(obj.map((a) => a.id_usuario_upload))).map((id_usuario_upload) => {
              return obj.find((a) => a.id_usuario_upload === id_usuario_upload);
            });

            obj.forEach((u, index) => {
              uploads.forEach((f, index) => {
                if (u.id_usuario_upload == f.id_usuario_upload) {
                  if (!f.anexos) f.anexos = [];
                  f.anexos.push(u);
                }
              });
            });

            resolve(uploads);
          }
          reject("Erro");
        } catch (e) {
          reject(e);
        }
      });
    },
  },
};
</script>

<style scope>
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } 
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
/*
* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Myriad Pro", Myriad, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
*/
.con-wrap {
  padding-bottom: 100px;
}

.con-breadcrumb {
  list-style: none;
  padding-left: 0px;
}
.con-breadcrumb .con-breadcrumb-item {
  display: inline-block;
}
.con-breadcrumb .con-breadcrumb-item a {
  color: #777;
  font-size: 18px;
  font-style: italic;
  text-decoration: none;
}
@media (max-width: 768px) {
  .con-breadcrumb .con-breadcrumb-item a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .con-breadcrumb .con-breadcrumb-item a {
    font-size: 12px;
  }
}
.con-breadcrumb .con-breadcrumb-item + .con-breadcrumb-item:before {
  content: "/";
  font-style: italic;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 767px) {
  .con-breadcrumb .con-breadcrumb-item + .con-breadcrumb-item:before {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.con-breadcrumb .active a {
  font-weight: bold;
  font-style: italic;
}

.con-modal .modal-content {
  border-radius: 0px;
  border: none;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
}
.con-modal .modal-content .con-modal-body {
  padding: 30px 100px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-header img {
  width: 220px;
  margin-bottom: 20px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content h4 {
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
  color: #0089cb;
  font-size: 20px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form {
  padding: 0px 30px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs {
  margin-top: 20px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs a {
  color: #a7a7a7;
  text-decoration: none;
  font-size: 10px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs label {
  font-size: 12px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs input[type="checkbox"] + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs input[type="checkbox"] {
  display: none;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs input[type="checkbox"] + label:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  border: 1.5px solid #454545;
  border-radius: 4px;
  width: 1em;
  height: 1em;
  padding: 2px;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs input[type="checkbox"] + label:active:before {
  transform: scale(0);
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs input[type="checkbox"]:checked + label:before {
  color: #0089cb;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #0089cb;
  border-color: #0089cb;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs .input-celular {
  margin-top: 10px;
  margin-bottom: 10px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs .input-celular label {
  margin-bottom: 0px;
  font-size: 16px;
  color: #2c2c2c;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs .input-celular .form-control {
  margin-top: -10px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs .input-celular .form-control::placeholder {
  color: #b2b2b2;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs .form-control {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #2c2c2c;
  border-radius: 0px;
  font-size: 20px;
  padding: 20px 0px;
  color: #2c2c2c;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs .form-control::placeholder {
  color: #2c2c2c;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-inputs .form-control + .form-control {
  margin-top: 15px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-footer small {
  color: #a7a7a7;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content .con-modal-login-form .con-modal-login-form-footer small a {
  font-size: 14px;
  color: #a7a7a7;
  font-weight: bold;
  text-decoration: none;
}

.rating-stars ul {
  list-style-type: none;
  padding: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.rating-stars ul > li.star {
  display: inline-block;
}

/* Idle State of the stars */
.rating-stars ul > li.star > i.fa {
  font-size: 2em;
  /* Change the size of the stars */
  color: #ccc;
  /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul > li.star.hover > i.fa {
  color: #ffcc36;
}

/* Selected state of the stars */
.rating-stars ul > li.star.selected > i.fa {
  color: #ffcc36;
}
.con-welcome {
  margin-top: 60px;
}
.con-welcome h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
}
.con-welcome h1 span {
  font-size: 2.5rem;
}

.con-timeline-curso::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.con-timeline-curso::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.con-timeline-curso::-webkit-scrollbar-thumb {
  background: #76c2ff;
}

.con-timeline-curso::-webkit-scrollbar-thumb:hover {
  background: #76c2ff;
}

.con-timeline-curso::-webkit-scrollbar-thumb:active {
  background: #76c2ff;
}

.con-timeline-curso::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-bottom: 10px solid #e8e8e8;
}

.con-timeline-curso::-webkit-scrollbar-track:hover {
  background: #e8e8e8;
}

.con-timeline-curso::-webkit-scrollbar-track:active {
  background: #e8e8e8;
}

.con-timeline-curso::-webkit-scrollbar-corner {
  background: transparent;
}

.con-timeline-curso {
  background-color: #3889ca;
  padding: 30px 20px 20px 20px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.con-timeline-curso .con-timeline-curso-list {
  list-style: none;
}
.con-timeline-curso .con-timeline-curso-list .con-timeline-curso-list-item {
  display: inline-block;
  text-align: center;
}
.con-timeline-curso .con-timeline-curso-list .con-timeline-curso-list-item i {
  color: #fff;
}
.con-timeline-curso .con-timeline-curso-list .con-timeline-curso-list-item p {
  color: #fff;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.con-timeline-curso .con-timeline-curso-list .con-timeline-curso-list-item + .con-timeline-curso-list-item {
  margin-left: 55px;
}

.con-info-curso {
  margin-top: 80px;
}
.con-info-curso h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
}
.con-info-curso h3 {
  font-size: 20px;
  color: #3889ca;
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
}
.con-info-curso h4 {
  font-size: 22px;
  color: #3889ca;
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
}
.con-info-curso p {
  font-size: 18px;
  color: #323232;
}
.con-info-curso .con-info-curso-tags {
  list-style: none;
  padding-left: 0px;
}
.con-info-curso .con-info-curso-tags li {
  display: inline-block;
  background-color: #d8d8d8;
  border-radius: 50px;
  padding: 3px 12px;
  margin-top: 10px;
  font-size: 14px;
  color: #a4a4a4;
}
.con-info-curso .con-info-curso-start .btn {
  font-size: 20px;
}
.con-info-curso .con-info-curso-docentes {
  margin-top: 20px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-header {
  background-color: #d8d8d8;
  padding: 10px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-header .con-info-curso-tutor .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-header .con-info-curso-tutor span {
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  color: #323232;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-header .con-info-curso-tutor span small {
  letter-spacing: 0px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar-thumb {
  background: #76c2ff;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar-thumb:hover {
  background: #76c2ff;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar-thumb:active {
  background: #76c2ff;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-bottom: 10px solid #e8e8e8;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar-track:hover {
  background: #e8e8e8;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar-track:active {
  background: #e8e8e8;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body::-webkit-scrollbar-corner {
  background: transparent;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body {
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 10px;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body h4 {
  font-size: 18px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body .con-info-curso-list {
  padding-left: 0px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body .con-info-curso-list .con-info-curso-list-item .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body .con-info-curso-list .con-info-curso-list-item + .con-info-curso-list-item {
  margin-top: 10px;
}

.con-aulas-curso {
  margin-top: 100px;
  position: relative;
}
.con-aulas-curso:after {
  content: "";
  width: 100%;
  height: 200px;
  position: absolute;
  left: 6px;
  bottom: 0;
  background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.con-aulas-curso .con-aulas-curso-header h2 {
  font-size: 60px;
}
.con-aulas-curso .con-aulas-curso-header .btn-secondary {
  font-size: 25px;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-thumb {
  background: #76c2ff;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-thumb:hover {
  background: #76c2ff;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-thumb:active {
  background: #76c2ff;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-bottom: 10px solid #e8e8e8;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-track:hover {
  background: #e8e8e8;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-track:active {
  background: #e8e8e8;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-corner {
  background: transparent;
}
.con-aulas-curso .con-aulas-curso-body {
  max-height: 550px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 50px;
  direction: rtl;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list {
  margin-top: 20px;
  list-style: none;
  padding-left: 50px;
  direction: ltr;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .con-aulas-curso-list-item .con-aulas-curso-list-item-icon {
  background-color: #ccc;
  width: 130px;
  height: 90px;
  text-align: center;
  border-radius: 50%;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .con-aulas-curso-list-item .con-aulas-curso-list-item-icon i {
  color: #fff;
  font-size: 50px;
  line-height: 90px;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .con-aulas-curso-list-item .con-aulas-curso-list-item-text {
  margin-left: 30px;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .con-aulas-curso-list-item .con-aulas-curso-list-item-text h4 {
  font-size: 25px;
  font-weight: 600;
  color: #a4a4a4;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .con-aulas-curso-list-item .con-aulas-curso-list-item-text p {
  font-size: 20px;
  color: #3a3535;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .con-aulas-curso-list-item + .con-aulas-curso-list-item {
  margin-top: 20px;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .concluido .con-aulas-curso-list-item-icon {
  background-color: #1fcc1b;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .em_andamento .con-aulas-curso-list-item-icon {
  background-color: #8ac6e9;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list .em_andamento .con-aulas-curso-list-item-text h4 {
  color: #8ac6e9;
}

.con-aulas-avaliacoes-curso {
  background-color: #42124c;
  margin-top: 50px;
  padding: 60px 0px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-header h2 {
  color: #fff;
  border-right: 3px solid #fff;
  font-size: 50px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-header h3 {
  color: #fff;
  font-size: 20px;
  font-style: italic;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body {
  margin-top: 100px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-thumb {
  background: #6a0c7e;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-thumb:hover {
  background: #6a0c7e;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-thumb:active {
  background: #6a0c7e;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-track {
  background: #33093c;
  border-bottom: 10px solid #33093c;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-track:hover {
  background: #33093c;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-track:active {
  background: #33093c;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-corner {
  background: transparent;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list {
  padding-left: 0px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 20px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list .con-aulas-avaliacoes-curso-list-item {
  color: #fff;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list .con-aulas-avaliacoes-curso-list-item .row [class*="col-"] {
  padding: 0px 15px !important;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list .con-aulas-avaliacoes-curso-list-item span {
  margin-left: 10px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list .con-aulas-avaliacoes-curso-list-item .avatar {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list .con-aulas-avaliacoes-curso-list-item .rating-stars ul > li.star > i.fa {
  font-size: 1.5em;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body .con-aulas-avaliacoes-curso-list .con-aulas-avaliacoes-curso-list-item + .con-aulas-avaliacoes-curso-list-item {
  margin-top: 20px;
}

.con-aulas-cta-curso {
  margin-top: 30px;
}
.con-aulas-cta-curso .btn {
  font-size: 30px;
}

.con-aulas-relacionados-curso {
  margin-top: 150px;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-header h2 {
  font-size: 35px;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-header .btn {
  margin-left: 20px;
  font-size: 25px;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-body {
  margin-top: 80px;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-body .btn {
  margin-top: 20px;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-body .con-aulas-relacionados-curso-item .con-aulas-relacionados-curso-item-content {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px 5px 10px;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-body .con-aulas-relacionados-curso-item .con-aulas-relacionados-curso-item-content p {
  font-size: 14px;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .con-planos-estudo-header {
    min-height: 200px;
  }
}
.con-planos-estudo-header #text-part-1 h1 {
  font-size: 85px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-style: italic;
}
@media (max-width: 767px) {
  .con-planos-estudo-header #text-part-1 h1 {
    font-size: 40px;
  }
}
.con-planos-estudo-header #text-part-2 h1 {
  font-size: 85px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-style: italic;
}
@media (max-width: 767px) {
  .con-planos-estudo-header #text-part-2 h1 {
    font-size: 40px;
    text-align: right;
  }
}

@media (max-width: 767px) {
  .con-planos-enem {
    padding: 40px 0px 150px 0px;
    background-size: 250%;
  }
}
@media (max-width: 767px) {
  .con-planos-enem .con-planos-enem-list .con-planos-enem-list-item {
    margin-top: 20px;
  }
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-title {
  width: 100%;
  height: 75px;
  text-align: center;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-title h3 {
  color: #fff;
  letter-spacing: 2px;
  font-style: italic;
  font-weight: bold;
  font-size: 25px;
  text-transform: uppercase;
  line-height: 25px;
  padding-top: 25px;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-video {
  background-position: center;
  background-size: cover;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-video .con-planos-enem-list-item-video-overlay {
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.2);
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-video .con-planos-enem-list-item-video-overlay i {
  color: #fff;
  font-size: 60px;
  margin-top: 20%;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc {
  background-color: #eee;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc hr {
  border-color: #42124c;
  width: 100%;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc .con-planos-enem-list-item-desc-body {
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc .con-planos-enem-list-item-desc-footer {
  padding-bottom: 10px;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc .con-planos-enem-list-item-desc-footer p {
  margin-bottom: 0px;
  font-weight: 800;
  font-style: italic;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 20px;
  color: #42124c;
  text-transform: uppercase;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc .con-planos-enem-list-item-desc-list {
  padding: 0px 30px;
  text-align: left;
  margin-bottom: 0px;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc .con-planos-enem-list-item-desc-list li {
  list-style: none;
  color: #42124c;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 16px;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc .con-planos-enem-list-item-desc-list li span {
  font-weight: 800;
  font-style: normal;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc .con-planos-enem-list-item-desc-list li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  font-size: 16px;
  color: #3889ca;
  margin-left: -30px;
}
.con-planos-enem .con-planos-enem-list .con-planos-enem-list-item .con-planos-enem-list-item-desc .con-planos-enem-list-item-desc-list li + li {
  margin-top: 5px;
}
.con-planos-enem .con-aquecimento-color .btn-aquecimento {
  background-color: #ec1550;
  box-shadow: -3px 3px 0px #42124c;
  color: #fff;
  font-weight: 800;
  font-size: 22px;
}
.con-planos-enem .con-aquecimento-color .btn-aquecimento:focus,
.con-planos-enem .con-aquecimento-color .btn-aquecimento:hover {
  background-color: #ec1550;
  box-shadow: 0px;
  color: #42124c;
}
.con-planos-enem .con-aquecimento-color .con-planos-enem-list-item-title {
  background-color: #ec1550;
}
.con-planos-enem .con-extensivo-color .btn-extensivo {
  background-color: #1d69a5;
  box-shadow: -3px 3px 0px #42124c;
  color: #fff;
  font-weight: 800;
  font-size: 22px;
}
.con-planos-enem .con-extensivo-color .btn-extensivo:focus,
.con-planos-enem .con-extensivo-color .btn-extensivo:hover {
  background-color: #1d69a5;
  box-shadow: 0px;
  color: #42124c;
}
.con-planos-enem .con-extensivo-color .con-planos-enem-list-item-title {
  background-color: #1d69a5;
}
.con-planos-enem .con-semi-extensivo-color .con-planos-enem-list-item-title h3 {
  text-align: start;
  padding: 15px 40px 0px 35px !important;
}
.con-planos-enem .con-semi-extensivo-color .btn-semi-extensivo {
  background-color: #42124c;
  box-shadow: -3px 3px 0px #ec1550;
  color: #fff;
  font-weight: 800;
  font-size: 22px;
}
.con-planos-enem .con-semi-extensivo-color .btn-semi-extensivo:focus,
.con-planos-enem .con-semi-extensivo-color .btn-semi-extensivo:hover {
  background-color: #42124c;
  box-shadow: 0px;
  color: #ec1550;
}
.con-planos-enem .con-semi-extensivo-color .con-planos-enem-list-item-title {
  background-color: #42124c;
}
.con-planos-enem .con-intensivao-color .btn-intensivao {
  background-color: #195b8f;
  box-shadow: -3px 3px 0px #ec1550;
  color: #fff;
  font-weight: 800;
  font-size: 22px;
}
.con-planos-enem .con-intensivao-color .btn-intensivao:focus,
.con-planos-enem .con-intensivao-color .btn-intensivao:hover {
  background-color: #195b8f;
  box-shadow: 0px;
  color: #ec1550;
}
.con-planos-enem .con-intensivao-color .con-planos-enem-list-item-title {
  background-color: #195b8f;
}

.con-aulas-particular .con-aulas-particular-header {
  position: absolute;
  margin-top: -50px;
}
@media (max-width: 767px) {
  .con-aulas-particular .con-aulas-particular-header {
    margin-left: -30px;
  }
}
.con-aulas-particular .con-aulas-particular-header h2 {
  background-color: #ec1652;
  padding: 10px 40px;
  display: block;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  font-weight: bolder;
  font-style: italic;
  font-size: 50px;
  position: relative;
}
@media (max-width: 767px) {
  .con-aulas-particular .con-aulas-particular-header h2 {
    font-size: 25px;
  }
}
.con-aulas-particular .con-aulas-particular-header h2:before {
  content: "";
  width: 100vw;
  height: 100%;
  position: absolute;
  background-color: #ec1652;
  top: 0;
  left: -100vw;
}
.con-aulas-particular .con-aulas-particular-body {
  padding-top: 200px;
  position: relative;
}
@media (max-width: 767px) {
  .con-aulas-particular .con-aulas-particular-body {
    padding-top: 50px;
  }
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar-thumb {
  background: #6490a9;
  border-radius: 40px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar-thumb:hover {
  background: #6490a9;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar-thumb:active {
  background: #6490a9;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-bottom: 10px solid #e8e8e8;
  border-radius: 40px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar-track:hover {
  background: #e8e8e8;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar-track:active {
  background: #e8e8e8;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list::-webkit-scrollbar-corner {
  background: transparent;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .row {
  flex-wrap: nowrap;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item {
  position: relative;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor p {
  font-size: 14px;
  font-weight: bold;
  color: #95ccf9;
  margin-top: 10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-classificacao {
  padding: 0px 30px;
  margin-top: 20px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-classificacao hr {
  border-color: #eee;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-classificacao p {
  color: #656565;
  margin-bottom: 2px;
  font-weight: bold;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-classificacao .questoes_respondidas {
  margin-top: -10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-classificacao .rating-stars ul > li.star + li.star {
  margin-left: -7px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-classificacao .rating-stars ul > li.star > i.fa {
  font-size: 15px;
  margin-top: 10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-estado {
  margin-top: -10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-estado img {
  width: 30px;
  height: 20px;
  border-radius: 0px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-estado span {
  margin-top: -10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-disciplina {
  padding: 0px 20px;
  margin-top: 20px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-disciplina label {
  /* Old browsers */
  background: -moz-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 8px 40px;
  border-radius: 15px;
  color: #fff;
  font-family: "Ubuntu Bold";
  font-size: 18px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor .con-aulas-particular-professores-list-item-professor-disciplina hr {
  border-color: #eee;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-header {
  background-color: #ececec;
  padding: 10px 2px 20px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Ubuntu Regular";
  color: #7c7c7c;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-body {
  background-color: #fff;
  border-top: 1px solid #ececec;
  min-height: 150px;
  position: relative;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  padding-bottom: 10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-body:before {
  content: "";
  height: 30px;
  width: 101%;
  left: -1px;
  background-color: #fff;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -20px;
  position: absolute;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-body:after {
  content: "";
  height: 30px;
  width: 101%;
  bottom: -20px;
  left: -1px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor-titulo {
  padding: 0px 20px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor-titulo p {
  color: #656565;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-professor-titulo div {
  border: 2px solid #edf2f0;
  border-radius: 2px;
  padding: 10px 20px;
  width: 100%;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-actions {
  padding: 0px 30px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-footer {
  background-color: #8ac6e9;
  padding: 30px 0px 15px 35px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-footer img {
  width: 40px;
}
.con-aulas-particular .con-aulas-particular-body .con-aulas-particular-professores-list .con-aulas-particular-professores-list-item .con-aulas-particular-professores-list-item-footer span {
  font-family: "Ubuntu Bold";
  line-height: 16px;
  margin-left: 10px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}
.con-aulas-particular .carousel-multi-prev {
  left: -80px;
  margin-top: 35%;
}
.con-aulas-particular .carousel-multi-next {
  right: -80px;
  margin-top: 35%;
}
.con-aulas-particular .carousel-multi-prev,
.con-aulas-particular .carousel-multi-next {
  position: absolute;
}
@media (max-width: 767px) {
  .con-aulas-particular .carousel-multi-prev,
  .con-aulas-particular .carousel-multi-next {
    display: none;
  }
}

.btn-aulas-particular {
  background-color: #d98840;
  border-radius: 15px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 16px;
}
.btn-aulas-particular:hover {
  color: #fff;
}
.btn-aulas-particular img {
  width: 30px;
  margin-right: 3px;
}

.btn-aulas-particular + .btn-aulas-particular {
  margin-top: 10px;
}

.con-welcome-classroom {
  margin-top: 60px;
}
.con-welcome-classroom img {
  width: 220px;
}
.con-welcome-classroom h1 {
  font-family: "Ubuntu Regular";
  font-size: 50px;
}
.con-welcome-classroom h1 span {
  padding: 5px 5px 5px 5px;
  background-color: #66c7eb;
  border-radius: 20px;
  font-family: "Ubuntu Regular";
  font-size: 50px;
}

.con-name-classroom {
  margin-top: 30px;
}
.con-name-classroom p {
  color: #afafaf;
  font-family: "Ubuntu Regular";
  font-size: 30px;
}

.con-timeline {
  /*padding-left: 2%;*/
  position: relative;
}
.con-timeline:before {
  content: "";
  position: absolute;
  background: #cccccc;
  margin-top: 25px;
  width: 90%;
  height: 0.3px;
}
.con-timeline-classroom ul {
  margin-bottom: 0px;
}
.con-timeline-classroom ul li {
  display: inline-block;
  list-style-type: none;
  width: 60px;
  text-align: center;
}
.con-timeline-classroom ul li .con-timeline-classroom-icon {
  background-color: #ccc;
  border-radius: 50%;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  top: 5px;
}
.con-timeline-classroom ul li .con-timeline-classroom-icon i {
  font-size: 25px;
  line-height: 40px;
}
.con-timeline-classroom ul li p {
  font-size: 9.5px;
  color: #ccc;
  font-family: "Ubuntu Light";
  margin-left: -25%;
  margin-top: 90%;
}
.con-timeline-classroom ul li + li {
  margin-left: 12px;
}
.con-timeline-classroom-visualizado .con-timeline-classroom-icon {
  background: #1fcc1b !important;
  border-radius: 50%;
  top: 5px !important;
}
.con-timeline-classroom-assistindo .con-timeline-classroom-icon {
  background: #8ac6e9 !important;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  text-align: center;
  top: 0 !important;
}
.con-timeline-classroom-assistindo .con-timeline-classroom-icon i {
  font-size: 30px !important;
  line-height: 50px !important;
}
.con-timeline-classroom-assistindo p {
  color: #8ac6e9 !important;
  margin-left: -15% !important;
}
.con-timeline-classroom-end .con-timeline-classroom-icon {
  background: #ffb80f !important;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  text-align: center;
  top: 0 !important;
}
.con-timeline-classroom-end .con-timeline-classroom-icon i {
  font-size: 30px !important;
  line-height: 50px !important;
}
.con-timeline-classroom-end p {
  color: #ffb80f !important;
  margin-left: -15% !important;
  font-family: "Ubuntu Bold";
  font-size: 10.7px !important;
  font-weight: bold !important;
}

.con-classroom {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;
}
.con-classroom .con-classroom-header {
  position: absolute;
  margin-top: -50px;
}
.con-classroom .con-classroom-body {
  position: relative;
}
.con-classroom .con-classroom-body .con-classroom-professores-list {
  padding-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .row {
  flex-wrap: nowrap;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item {
  position: relative;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor p {
  font-size: 14px;
  font-weight: bold;
  color: #65abd3;
  margin-top: 10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-classificacao {
  padding: 0px 30px;
  margin-top: 20px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-classificacao hr {
  border-color: #eee;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-classificacao p {
  color: #656565;
  margin-bottom: 2px;
  font-weight: bold;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-classificacao .questoes_respondidas {
  margin-top: -10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-classificacao .rating-stars ul > li.star + li.star {
  margin-left: -7px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-classificacao .rating-stars ul > li.star > i.fa {
  font-size: 15px;
  margin-top: 10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-estado {
  margin-top: -10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-estado img {
  width: 30px;
  height: 20px;
  border-radius: 0px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-estado span {
  margin-top: -10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-disciplina {
  padding: 0px 20px;
  margin-top: 20px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-disciplina label {
  /* Old browsers */
  background: -moz-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 8px 40px;
  border-radius: 15px;
  color: #fff;
  font-family: "Ubuntu Bold";
  font-size: 18px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor .con-classroom-professores-list-item-professor-disciplina hr {
  border-color: #eee;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-header {
  background-color: #ececec;
  padding: 10px 2px 20px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Ubuntu Regular";
  color: #7c7c7c;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-body {
  background-color: #fff;
  border-top: 1px solid #ececec;
  min-height: 150px;
  position: relative;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  padding-bottom: 10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-body:before {
  content: "";
  height: 30px;
  width: 100%;
  left: 0;
  background-color: #fff;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -20px;
  position: absolute;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-body:after {
  content: "";
  height: 30px;
  width: 101%;
  bottom: -20px;
  left: -1px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor-titulo {
  padding: 0px 20px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor-titulo p {
  color: #656565;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-professor-titulo div {
  border: 2px solid #edf2f0;
  border-radius: 2px;
  padding: 10px 20px;
  width: 100%;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-actions {
  padding: 0px 30px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-footer {
  background-color: #8ac6e9;
  padding: 30px 0px 15px 35px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-footer img {
  width: 40px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .con-classroom-professores-list-item .con-classroom-professores-list-item-footer span {
  font-family: "Ubuntu Bold";
  line-height: 16px;
  margin-left: 10px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}

.btn-classroom {
  background-color: #d98840 !important;
  border-radius: 15px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff !important;
  font-size: 16px;
}
.btn-classroom:hover {
  color: #fff;
}
.btn-classroom img {
  width: 30px;
  margin-right: 3px;
}

.btn-classroom + .btn-classroom {
  margin-top: 10px;
}

.con-classroom-anotacoes {
  min-height: 150px;
  position: relative;
  padding-bottom: 10px;
}
.con-classroom-anotacoes:before {
  /*
  content: "";
  height: 30px;
  width: 100%;
  top: 40px;
  background-color: #fff;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;*/
}
.con-classroom-anotacoes:after {
  /*
  content: "";
  height: 30px;
  width: 100%;
  bottom: 0px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;*/
}
.con-classroom-anotacoes-body {
  background-color: #fff;
  padding-top: 10px;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}
.con-classroom-anotacoes-header {
  background-color: #ececec;
  padding: 10px 2px 20px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Ubuntu Medium";
  font-size: 20px;
  color: #7c7c7c;
}
.con-classroom-anotacoes ul {
  background-color: transparent;
  padding: 5px 20px 20px 20px;
}
.con-classroom-anotacoes ul li {
  list-style-type: none;
  border-bottom: 1px solid #ececec;
  color: #bcbcbc;
  font-family: "Ubuntu Regular";
  font-size: 14px;
  min-height: 30px;
}
.con-classroom-anotacoes ul li + li {
  margin-top: 10px;
}

.con-classroom-biblioteca-cta {
  margin-top: 20px;
}
.con-classroom-biblioteca-cta .btn-biblioteca {
  background-color: #d98840;
  border-radius: 50px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-biblioteca-cta .btn-biblioteca:hover {
  color: #fff;
}
.con-classroom-biblioteca-cta .btn-biblioteca img {
  width: 30px;
  margin-right: 3px;
}

.con-classroom-aula-options {
  margin-top: 20px;
}
.con-classroom-aula-options .con-classroom-options-prev {
  /* Old browsers */
  background: -moz-linear-gradient(left, #e0e0e0 0%, #c9c9c9 51%, #b7b7b7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #e0e0e0 0%, #c9c9c9 51%, #b7b7b7 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #e0e0e0 0%, #c9c9c9 51%, #b7b7b7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  margin-top: 20px;
  border-radius: 50px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-aula-options .con-classroom-options-prev:hover {
  color: #fff;
}
.con-classroom-aula-options .con-classroom-options-next {
  margin-top: 20px;
  /* Old browsers */
  background: -moz-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border-radius: 50px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-aula-options .con-classroom-options-next:hover {
  color: #fff;
}
.con-classroom-aula-options .con-classroom-options-next img {
  width: 30px;
  margin-right: 3px;
}
.con-classroom-aula-options .con-classroom-options-exit {
  margin-top: 20px;
  background-color: #f42727;
  border-radius: 50px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-aula-options .con-classroom-options-exit:hover {
  color: #fff;
}
.con-classroom-aula-options .con-classroom-options-exit img {
  width: 30px;
  margin-right: 3px;
}

.con-classroom-ranking {
  position: relative;
  margin-top: 40px;
}
.con-classroom-ranking p {
  margin-bottom: 1px;
  font-size: 18px;
  font-family: "Ubuntu Regular";
  color: #323232;
}
.con-classroom-ranking .con-classroom-ranking-content {
  background-color: #ececec;
  border-radius: 8px;
}
.con-classroom-ranking .con-classroom-ranking-content::after {
  content: "";
  width: 100%;
  height: 20px;
  margin-top: -15px;
  background-color: #ececec;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  position: absolute;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-header {
  background-color: #e0e0e0;
  border-radius: 8px;
  border-radius: 8px;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-header .con-classroom-ranking-header-avatar {
  background-size: cover;
  background-position: center center;
  width: 45%;
  height: 60px;
  border-radius: 8px;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-header .con-classroom-ranking-header-level {
  margin-left: 20px;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-header .con-classroom-ranking-header-level .con-classroom-ranking-header-level-text {
  font-family: "Ubuntu Regular";
  font-size: 18px;
  line-height: 18px;
  margin-left: 10px;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body {
  padding: 40px 20px 0px 20px;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body .con-classroom-ranking-medals-list {
  list-style: none;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body .con-classroom-ranking-medals-list li {
  display: inline-block;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body .con-classroom-ranking-medals-list li img {
  margin-top: -20px;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body .con-classroom-ranking-medals-list li + li {
  margin-left: 3px;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body .con-classroom-ranking-trophies-list {
  list-style: none;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body .con-classroom-ranking-trophies-list li {
  display: inline-block;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body .con-classroom-ranking-trophies-list li img {
  margin-top: -25px;
}
.con-classroom-ranking .con-classroom-ranking-content .con-classroom-ranking-body .con-classroom-ranking-trophies-list li + li {
  margin-left: 3px;
}
.con-classroom-ranking .con-classroom-ranking-verregras {
  background-color: #d98840;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-ranking .con-classroom-ranking-verregras:hover {
  color: #fff;
}

.plyr--video {
  border-radius: 20px;
  background-color: transparent !important;
}

.plyr__video-wrapper {
  background-color: transparent !important;
}

.fast-aula-alternativa:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.fast-aula-alternativa-escolhida {
  background-color: rgb(192, 192, 192);
}
.fast-aula-alternativa-escolhida-correta {
  background-color: rgb(82, 255, 97);
}
.fast-aula-alternativa-escolhida-incorreta {
  background-color: rgb(255, 58, 58);
}

.VueCarousel-slide {
  height: 100%;
}

/* Personalizado */
@media (max-width: 768px) {
  .con-classroom {
    padding: 0;
  }
  #sala {
    padding: 0 !important;
  }
  .fast-aula-conteudo-principal {
    box-shadow: 0 0 !important;
    padding: 10px !important;
  }
  .con-classroom-aula-options {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
  }
  .con-classroom-aula-options .con-classroom-options-next,
  .con-classroom-aula-options .con-classroom-options-prev {
    margin-top: 0;
  }
  .con-timeline {
    margin-bottom: 0;
  }
  .VueCarousel-wrapper img {
    max-width: 100%;
  }
}
/* Ajuste da árvore de lista de aulas */
.vtl-node-main {
  position: relative;
}
.vtl-operation {
  position: absolute;
  right: -24px;
}
.max-width-100vw {
  max-width: 100vw !important;
}
/* Bug no carousel no firefox */
@-moz-document url-prefix() {
  .VueCarousel-slide-adjustableHeight {
    display: block !important;
  }
}
</style>
